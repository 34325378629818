<template><section><h1>Change Log</h1>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v32.2.1...v32.2.2">32.2.2</a> (2025-03-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assembly transactions report:</strong> update corrects missing fields (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a41860f5272f5c306c5ccbc9785d9acfedd08949">a41860f</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b5929c949c9c63bf0c1a0562718a4cbc2539ab37">b5929c9</a>)</li>
<li><strong>Schema:</strong> update to v52.4.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/09a314a8e6f74109b28eada9c69f7ced2b92967f">09a314a</a>)</li>
<li><strong>Schema:</strong> update to v52.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b273c9d335a04af68ef1876a70e9f4ff5819c558">b273c9d</a>)</li>
<li><strong>Schema:</strong> update to v52.5.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1b6defe116fd0681279131b57967cf1030480579">1b6defe</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v32.2.0...v32.2.1">32.2.1</a> (2025-03-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Change sets tab:</strong> update query to return more data (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fee4e4f9860754cb86b83879a77695f4448bc80b">fee4e4f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v32.1.1...v32.2.0">32.2.0</a> (2025-03-20)</h1>
<h3>Features</h3>
<ul>
<li><strong>Tables:</strong> ability to sort on the new part reference column (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2f75a2b70c8505f9b368e5ee16f13fbd0c582407">2f75a2b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v32.1.0...v32.1.1">32.1.1</a> (2025-03-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware list status:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c690758591ef6b6dd98a0af0f81a2bd8d6c0f5a2">c690758</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v32.0.1...v32.1.0">32.1.0</a> (2025-03-20)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assembly Transactions:</strong> updated export layout (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/aa707dc14b306ba19202964ff92f6d2e30fa143b">aa707dc</a>)</li>
<li><strong>Hardware list status report:</strong> export update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e809371505dbb6b4733eaa49f2fbea1304794e34">e809371</a>)</li>
<li><strong>Inventory Report:</strong> fixed rendering issue on results page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/543f2d2ee5f18f4ba63c374af63ddfb3945f4fce">543f2d2</a>)</li>
<li><strong>Return inventory:</strong> update selection of inventory to function as intended (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9764979a5d3fbfb008429175a39e6d524c7a0fb8">9764979</a>)</li>
<li><strong>Schema:</strong> update to v52.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/77a7839ddb286e774d63c5c99673a7c463cee9ee">77a7839</a>)</li>
<li><strong>Schema:</strong> update to v52.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/560d9b99296e596947d94ef0602b3a1c5fb19508">560d9b9</a>)</li>
<li><strong>Schema:</strong> update to v52.3.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8a94100765b0dafb52fb5109e862b59f20bfbe9b">8a94100</a>)</li>
<li><strong>Schema:</strong> update to v52.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cf8628ace0db5b1cae23d9e7d47bdf85a94eeca6">cf8628a</a>)</li>
<li><strong>Schema:</strong> update to v52.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/798817a19d41848c5cbe9c1ceaad9f94d718061c">798817a</a>)</li>
<li><strong>Transactions Report:</strong> updated transactions report export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f85f09426cb23674c374c553fb031268a7173e40">f85f094</a>)</li>
<li><strong>User ChangeSetApprovals:</strong> fix opened date showing html (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e4bfa2e0bc8467481e539afdae6632ba2667543a">e4bfa2e</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Hardware list status report:</strong> improvements based on user feedback (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/277b94d6453d2369b9c2a48f1f64a0461ac570ee">277b94d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v32.0.0...v32.0.1">32.0.1</a> (2025-03-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Calibration Activity creation:</strong> corrected parameters (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b294ce3c9fc32bbde186530ce53ed599065decae">b294ce3</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3f52be94c1ecc016e7dfa759f517aacae532616e">3f52be9</a>)</li>
<li><strong>Home:</strong> make hitbox for hardware search checkboxes smaller (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8d80927b81ebedcefcac66f0841087da8ce4f5b6">8d80927</a>)</li>
<li><strong>Schema:</strong> update to v52.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f6051ea19d56f66bd158a81084d807463fcca131">f6051ea</a>)</li>
<li><strong>Schema:</strong> update to v52.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/57c8af57d231670dc1e32c8dd6cf4dcb9a70b94b">57c8af5</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.11.4...v32.0.0">32.0.0</a> (2025-03-12)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v52.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6838a6fe409813f81735bfe69ae743bb89e84fa3">6838a6f</a>)</li>
<li><strong>Side nav:</strong> removing Contract from the display (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f4846062d2f70d250278f9713b3ddef9d0810e72">f484606</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Progress:</strong> ever forward. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7ca2b277e52adc3db25cad92c7417c6a66a08a1b">7ca2b27</a>)</li>
<li><strong>Reports:</strong> updated 1489 summary, no activity, Biannual reports (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/409e60dad03b0b7becebc9886e947b5dbf28d208">409e60d</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Progress:</strong> updated progress indictors throughout the app. no obvious visual change expected</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.11.3...v31.11.4">31.11.4</a> (2025-03-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b888f911b0604b67af4ebd86b90eff37453e7fe2">b888f91</a>)</li>
<li><strong>Schema:</strong> update to v52.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8b7563aa755f01bf632c0d0f9cb1bb07d5742443">8b7563a</a>)</li>
<li><strong>Schema:</strong> update to v52.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/929dd9aa499526d93350fcfacc2873d12b15f101">929dd9a</a>)</li>
<li><strong>Schema:</strong> update to v52.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0873fb3c539e589e04891b1a2a31205288a834b0">0873fb3</a>)</li>
<li><strong>Spring cleaning:</strong> remove unused components (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1d331df6138ce494492764014547df88a17fde23">1d331df</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.11.2...v31.11.3">31.11.3</a> (2025-03-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Contracts update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1082ae544d003eba65ca259b09b271c6a5589323">1082ae5</a>)</li>
<li><strong>Item drawing display:</strong> update ui. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e3b0afceee7c8761b45fed1308d1b1e92cb53f9f">e3b0afc</a>)</li>
<li><strong>Item Instance:</strong> updated forms on information tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/21d977dcf689f2962b070c7940d89bd8b0c2c93d">21d977d</a>)</li>
<li><strong>Movement:</strong> corrected issue with displaying the hardware selected/moved (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f07dc0790176c56f21c2f19f45476afbc4336444">f07dc07</a>)</li>
<li><strong>Schema:</strong> update to v51.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8c8c0d6c29266b1bb219bcaa60842279c01455c3">8c8c0d6</a>)</li>
<li><strong>Schema:</strong> update to v51.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/25ce5b669e4b2c5341b76a37e8cb5e67d409608c">25ce5b6</a>)</li>
<li><strong>Schema:</strong> update to v51.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b9e27685b3c612d3bb5273ba7c596e1b61107fa3">b9e2768</a>)</li>
<li><strong>Schema:</strong> update to v51.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7783c7625db6bf561e4e4b1e581b97031b187bc6">7783c76</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.11.1...v31.11.2">31.11.2</a> (2025-03-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assmebly transactions report:</strong> update adds ui standards to old report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b1e35a0942c479e0a1b4aeadc36efb428d1620fb">b1e35a0</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/54c758bf56ede5efab2655aba25dae1a19106f3c">54c758b</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7d0d8068766ff72adab762b95a030207720dac38">7d0d806</a>)</li>
<li><strong>Inventory movement:</strong> correct bug relating to inventory selection (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/389545e3c708b30373a2c71c4140b92df2273de0">389545e</a>)</li>
<li><strong>Schema:</strong> update to v51.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d7e593f733b08b3e7c1588f9ee8651e4a8078525">d7e593f</a>)</li>
<li><strong>Schema:</strong> update to v51.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5c298185a3635ab0a55b411bb2a6d5321cde1948">5c29818</a>)</li>
<li><strong>Transaction report:</strong> implemented new control to display locations within a table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e6d31a6a761ee876e3555e4a3ace9b7536efcab3">e6d31a6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.11.0...v31.11.1">31.11.1</a> (2025-03-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b9d65e6e5b4376cf0cacea31a21a56dcbb088e93">b9d65e6</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.10.0...v31.11.0">31.11.0</a> (2025-03-04)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Biannual 1324:</strong> remove discount double check hr tag (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fd09e58da7b7739912ee7ec0c697b42ef6583c34">fd09e58</a>)</li>
<li><strong>Hardware list manage:</strong> update integrates new endpoint for the hardware list status (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4ba62d0ef847a474d43d70819ce99746ed7439f8">4ba62d0</a>)</li>
<li><strong>Hardware list status report:</strong> correct visual bug (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/87f3e308d9f3fe856bad5ac1be476b8722444486">87f3e30</a>)</li>
<li><strong>Hardware list status:</strong> added a checkbox for including assembly children in result (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f99c2c1bd1df1c23283fbb2d60be321ec584489b">f99c2c1</a>)</li>
<li><strong>Hardware search:</strong> minimize hit box for checkboxes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/614a933418baf1350d6da1fe6b3356e5d89a5f3b">614a933</a>)</li>
<li><strong>IRC:</strong> fixed linking to 911 tags (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ecc7400a694d6998246e78a31a3a12fda8d1e0bb">ecc7400</a>)</li>
<li><strong>Mini assembly:</strong> fixed linkage to Inventory transactions report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6d1fc701d6b516b67fd961c9fe92e7df63ef1999">6d1fc70</a>)</li>
<li><strong>Return Inventory/Movement out:</strong> updated old control to use api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/176f323222e5201dab0f01a54a5b28b620a956a7">176f323</a>)</li>
<li><strong>Return inventory:</strong> update link to item instance manage page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1bfdc5f25b32657bb263ca63b716cce5158e406c">1bfdc5f</a>)</li>
<li><strong>Schema:</strong> update to v50.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2777bfc15f467965f2725d796b55dc54c1b11023">2777bfc</a>)</li>
<li><strong>Schema:</strong> update to v50.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c392e418c3cc6141782fb6d08150aa2a6fa3d8af">c392e41</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Hardware list status report:</strong> rewrite using the new api (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ef1ab674088325aa6cbc11601946b13fe55d166f">ef1ab67</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.9.2...v31.10.0">31.10.0</a> (2025-02-28)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>InventoryInTransit:</strong> fixed hardware display on in transit table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/081f00875e040cb43485ceb4687607f1ce98499d">081f008</a>)</li>
<li><strong>Item drawing:</strong> corrected issue showing hardware on the tab if the dn contained a special character (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/837b873ec1c870d4beb414c20aac397035481218">837b873</a>)</li>
<li><strong>Links:</strong> fixed links for drawings, item masters and events (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fa20bb6f8a6938b17a43f8ecff65972c7eab65aa">fa20bb6</a>)</li>
<li><strong>ReportingPaperwork:</strong> update required report parameters (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/df8b4bfc9db8e578a3671be42bcffbe2133b3909">df8b4bf</a>)</li>
<li><strong>Schema:</strong> update to v50.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4fc57ebe14aa3e243d7c27c3600a7dad337c4754">4fc57eb</a>)</li>
<li><strong>Schema:</strong> update to v50.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/106831d374163c5758caf1e51ebcc4452cb07336">106831d</a>)</li>
<li><strong>Schema:</strong> update to v50.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ea2f5af868588841782bb3d4c49800c39b87d10a">ea2f5af</a>)</li>
<li><strong>TabItemInstanceInventory:</strong> fix closing tag (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c9fbcf51f57532b2392778b824fbe881ea7c64f5">c9fbcf5</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v50.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f9bf3e8e4f0702c62c17491e5d9e951765b4f068">f9bf3e8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.9.1...v31.9.2">31.9.2</a> (2025-02-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v49.8.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a8e3d7dac49a68dec110ffac5878e13998b51c75">a8e3d7d</a>)</li>
<li><strong>Schema:</strong> update to v49.9.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c21a54a61321aaf14e11d4a023d8e4002e285250">c21a54a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.9.0...v31.9.1">31.9.1</a> (2025-02-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>BinTransfer:</strong> added back click event (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0279f0965baea84968a5ac58773a768c5fd3cf72">0279f09</a>)</li>
<li><strong>Return inventory:</strong> update location selection  to display Room (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a2736c4eed5625a7a9ea47eb8d991e093182afff">a2736c4</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.8.3...v31.9.0">31.9.0</a> (2025-02-20)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2c6863b2b33a13cfe102601a5105a64550f4ff60">2c6863b</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/dfb0fe7de6d4387b8f142ced09909e08f7e0c5c0">dfb0fe7</a>)</li>
<li><strong>Schema:</strong> update to v49.8.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/038b7131feb96bfc107ed2915497d22d1946a337">038b713</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Merge ItemInstance:</strong> updating steps control, hardware displays (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1895bc5fac17ce5acf0073adcc8f9b2fd9952e48">1895bc5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.8.2...v31.8.3">31.8.3</a> (2025-02-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bc893053fe3ea3dd3960ad4134c8f49c4e200295">bc89305</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.8.1...v31.8.2">31.8.2</a> (2025-02-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ca34e208295716dbdf4e210dd11b74084d0d2d4b">ca34e20</a>)</li>
<li><strong>Events:</strong> update spacing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3b09b4ed6eaeb8c73532cc94fa27f7f57b660cb9">3b09b4e</a>)</li>
<li><strong>Hardware display:</strong> AHD fixed (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b647c837cdd1d44aa8e6094e9596017f1576c995">b647c83</a>)</li>
<li><strong>Hardware display:</strong> AHD fixes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/abf3f61ca04672ad53af39c778e9c0f01d2df75d">abf3f61</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.8.0...v31.8.1">31.8.1</a> (2025-02-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Cache:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9251c110eb49a2062bb5c83c1da23608af99635a">9251c11</a>)</li>
<li><strong>Paperwork report:</strong> AHD fixed (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9a1f85ea7ab2f072ff3db54f612bbb51d6b12004">9a1f85e</a>)</li>
<li><strong>Report views:</strong> add ctx to share link buttons (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e88f0292dfca68ead153627ce89fbac044ca4b3c">e88f029</a>)</li>
<li><strong>Schema:</strong> update to v49.7.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bc491c25a5a47d3666045fa116c32798b9d9f00e">bc491c2</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.7.3...v31.8.0">31.8.0</a> (2025-02-17)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Item master calibrations:</strong> update code to build from a newly created nodeId (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6c24d6a6bec1899ca79d208c27463d6959aa23b2">6c24d6a</a>)</li>
<li><strong>Schema:</strong> update to v49.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a3a64f28b50903542b362f8af159dee6e3c4523c">a3a64f2</a>)</li>
<li><strong>Schema:</strong> update to v49.6.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/97ca19fc395b59e5afd100f90f2c4f9752dd623e">97ca19f</a>)</li>
<li><strong>Schema:</strong> update to v49.6.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2db4e3afcf8575c2c1589c6aa360d5c812c643bb">2db4e3a</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Inventory poof:</strong> created a way to poof inventory in a pinch (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a14ca060c0e117899131cf0e57ebc2c55fdb2982">a14ca06</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.7.2...v31.7.3">31.7.3</a> (2025-02-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/81eaaf70e94e4106073cce06243b10d4cd4b04c6">81eaaf7</a>)</li>
<li><strong>DocumentsPastDue:</strong> updated query and headers (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/007970fe36f65413aa51dae947b2342282345b21">007970f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.7.1...v31.7.2">31.7.2</a> (2025-02-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AHD:</strong> fix AHD displays on multiple report views (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/85d45b19080803a85b7917602711875820437e10">85d45b1</a>)</li>
<li><strong>Change set:</strong> manage page update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0719c5f16cc9663359d567e34f75119c31947118">0719c5f</a>)</li>
<li><strong>Inventory Transactions Report:</strong> fetching all comments on load (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d783092152aadea4fa5e83b473ac228825424a86">d783092</a>)</li>
<li><strong>LimitedLifeReport:</strong> removed subType, updated AHD in expanded row (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6b910aa553b608877d3c52a11708218a4ee13724">6b910aa</a>)</li>
<li><strong>Schema:</strong> update to v49.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1fff03dc3f228c867c4ae9bf3b1661e0c815bfcf">1fff03d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.7.0...v31.7.1">31.7.1</a> (2025-02-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Change set tab:</strong> update fixes dates on this tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/aa963987e412b17c8980dedef026019d68067ef8">aa96398</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.14...v31.7.0">31.7.0</a> (2025-02-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Change sets:</strong> updated the manage view to new ux and api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a200c3e48802a4608866b081fa06ade445bca29f">a200c3e</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/267157943fe5b96b537694e769f23e11f1f3600a">2671579</a>)</li>
<li><strong>Hardware List Status:</strong> updated validation check (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/adcffb9152f2f65b1d86c6825d98db321c6488c9">adcffb9</a>)</li>
<li><strong>Inventory Transactions:</strong> updated class on report export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d2892f52f1a3fafd2bde275dfbc21e6d787ab5e5">d2892f5</a>)</li>
<li><strong>item drawing:</strong> updated the hardware tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1ec3020aff24c7c37b98a1b163f7e1ff28c61085">1ec3020</a>)</li>
<li><strong>Movement Out:</strong> disable expected return date if already exists (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0097519dacb89600d89b24b52bec4d1c4a6f7692">0097519</a>)</li>
<li><strong>Schema:</strong> update to v48.10.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/006c17d4ff2bedde4bb4d4871fb0a00798d3ab73">006c17d</a>)</li>
<li><strong>Schema:</strong> update to v48.10.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8ff9e568e6281970dbb91014ad11607b0b3eb087">8ff9e56</a>)</li>
<li><strong>Schema:</strong> update to v48.12.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c342274a46918f78f6f242fb52e5fc8ee3956cc4">c342274</a>)</li>
<li><strong>Schema:</strong> update to v48.9.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f15a1a6c85de242a0d31954ff4ef88f531d28bf7">f15a1a6</a>)</li>
<li><strong>Schema:</strong> update to v48.9.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/35060dcd29bc13ea693ebe685ca131306f44281f">35060dc</a>)</li>
<li><strong>Schema:</strong> update to v49.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e7e113e44a257b3c9875de5ed360bdc7436c0ba3">e7e113e</a>)</li>
<li><strong>Schema:</strong> update to v49.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9b2f2f86490e024b61d44ed911ce49d4b94da388">9b2f2f8</a>)</li>
<li><strong>Schema:</strong> update to v49.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3c3954a48082a57bc504262dfd176230f6f85c5f">3c3954a</a>)</li>
<li><strong>Schema:</strong> update to v49.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a0423a915b933970a14619aa5fcd7bd90f8411df">a0423a9</a>)</li>
<li><strong>Schema:</strong> update to v49.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e9bf13846de382e9b8958b5c03949e87bb3b16c1">e9bf138</a>)</li>
<li><strong>Schema:</strong> update to v49.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a527b21165fd8ce1e82bf0ef359a6c9fa41c748c">a527b21</a>)</li>
<li><strong>Schema:</strong> update to v49.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/aa36dda5f613f822a1ce7e53411ff18e6fabd39d">aa36dda</a>)</li>
<li><strong>User create:</strong> correct issue saving the user's middle name (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bb597a7ad4bd3cabe1b1fc21e9d7b1c00565a129">bb597a7</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Item instance:</strong> update to require authority information on a configuration change (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2df0b9edb541490312ab042f30187a3cd320aa4e">2df0b9e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.13...v31.6.14">31.6.14</a> (2025-01-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>911 tags:</strong> fixed shelf life date on export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8f233509be9fc1eb89d47513e9094c1b5a6a1d7f">8f23350</a>)</li>
<li><strong>Item Instance:</strong> updated transactions table stock and bin order (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4a392ff93ec4d5ba65b414581eb90656d7270b6e">4a392ff</a>)</li>
<li><strong>Receive inventory:</strong> unlocked the default here for location search (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7388bbdb8a48e3d21fe5a70c73f864c190e98b5d">7388bbd</a>)</li>
<li><strong>Schema:</strong> update to v48.8.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/06d54b2914dc40e428394adbea2943679264f559">06d54b2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.12...v31.6.13">31.6.13</a> (2025-01-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.7.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/722f3465aafd2e877e6e997b9c3dd740b823cb95">722f346</a>)</li>
<li><strong>Schema:</strong> update to v48.8.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d9ae11e8facc391d4887ded4722ece515e23519d">d9ae11e</a>)</li>
<li><strong>Schema:</strong> update to v48.8.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5184f39a01f89c3a67ec06954152db71c55898a7">5184f39</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.11...v31.6.12">31.6.12</a> (2025-01-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.7.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0c9971556e01b42141a4eed150021ae3a1cf38c1">0c99715</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.10...v31.6.11">31.6.11</a> (2025-01-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> implement new part display component (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d762e0f8ec33288cf54dc8547a42aa616c70ef92">d762e0f</a>)</li>
<li><strong>Schema:</strong> update to v48.6.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/54cbd60744cad8b5f181a59bc9a01ee9565d8332">54cbd60</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.9...v31.6.10">31.6.10</a> (2025-01-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/dd149684f51bc44f63ccda7d64555e70b48b7143">dd14968</a>)</li>
<li><strong>Schema:</strong> update to v48.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8ad385e7fd2ce961c7a9b568f45ca6b641a27702">8ad385e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.8...v31.6.9">31.6.9</a> (2025-01-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Historical records:</strong> remove constraint for disabling the button (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f05e7ecbfc8ef6df40a1b555ef11fb4122579ceb">f05e7ec</a>)</li>
<li><strong>Schema:</strong> update to v48.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/89596fdc8233a01fdc64fe9b77869a96a24203ca">89596fd</a>)</li>
<li><strong>Schema:</strong> update to v48.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1fbbb0fb9a6e1e97166cba9a4484d071086f9e81">1fbbb0f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.7...v31.6.8">31.6.8</a> (2025-01-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/10818a121ddc1078d7628e92ad783d5872903c72">10818a1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.6...v31.6.7">31.6.7</a> (2025-01-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ArchiveHardware:</strong> fix tables on archive item master and item drawing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/eddcab2cade8c45709a9ba3e8a13c88290e496b9">eddcab2</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2cd95bd39b9a195591653ee8d1f1929ae3e6428e">2cd95bd</a>)</li>
<li><strong>Schema:</strong> update to v48.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7e392c2d3a30ac3222d0779ad55543d739b257bc">7e392c2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.5...v31.6.6">31.6.6</a> (2025-01-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>IR:</strong> updated redirect to 911 tags from initial receipt (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/63184dbf6e9a4bfe5ec56298f6c30eced762dce1">63184db</a>)</li>
<li><strong>Schema:</strong> update to v48.1.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8c837ba3f0f07bb2bb8c20315a3005f0efddaa9c">8c837ba</a>)</li>
<li><strong>Schema:</strong> update to v48.1.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/51e00a9ccf1266b95c303b12db6c373560c9ade8">51e00a9</a>)</li>
<li><strong>Schema:</strong> update to v48.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/44fc55850bc07d55c68984ce0179102c3a3e7232">44fc558</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.4...v31.6.5">31.6.5</a> (2025-01-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7ab8932bb5356c35b47f17cb4d2efe091b91a241">7ab8932</a>)</li>
<li><strong>Schema:</strong> update to v48.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ee91b22a8a3aeac8bef7f1b9baf23543ee6ec907">ee91b22</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.3...v31.6.4">31.6.4</a> (2024-12-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>PDF:</strong> update font code (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/091bdad05e5b38f8f5dfdc1d58db80b7ba0fd222">091bdad</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.2...v31.6.3">31.6.3</a> (2024-12-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Change sets:</strong> comments no longer showing html (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a9482ec79ad3dec1c41aef42c5b278f4cfd6163d">a9482ec</a>)</li>
<li><strong>Item instance:</strong> manage page header update and AHD updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9b5351460b0833ca4ecaf5125adbf356883c0b35">9b53514</a>)</li>
<li><strong>Item master:</strong> manage page header update and AHD updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4981588cafce71fb941c86008c7038566d801137">4981588</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.1...v31.6.2">31.6.2</a> (2024-12-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2fb9aafc774d63962bae5911a79047d361d1ef51">2fb9aaf</a>)</li>
<li><strong>Schema:</strong> update to v48.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d8f7986453d17f694b1024e4d0cc6d11c4843280">d8f7986</a>)</li>
<li><strong>Schema:</strong> update to v48.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/473068f273fb154265aafdf04b1492f21e5c7d1a">473068f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.6.0...v31.6.1">31.6.1</a> (2024-12-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4a5ff6e84cf15d7663e588cbdac21da96d6f9941">4a5ff6e</a>)</li>
<li><strong>Schema:</strong> update to v48.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bbdb5ee6baf774b33263864425513090116cedd0">bbdb5ee</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.5.9...v31.6.0">31.6.0</a> (2024-12-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Document line items:</strong> update to change color (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a749b224bc566c8dad194703a381626c16062f7a">a749b22</a>)</li>
<li><strong>IR:</strong> updated creation summary, added border for locations (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8206ccb93be770cca6d1846ae2217d4e393fcfbd">8206ccb</a>)</li>
<li><strong>Item instance subtype:</strong> correctly accounting for a null subtype and displaying a dash (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1015e625d1b3fef29c8e03e7f6471c13fca4aeb4">1015e62</a>)</li>
<li><strong>Movement:</strong> updated logic when canceling form (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f42636ede5e12562601ae50da38d9eff8182b3bc">f42636e</a>)</li>
<li><strong>Readiness:</strong> cei number column width (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cc900eac0a5c860cb0fbe1438cd5173b6f9dfb45">cc900ea</a>)</li>
<li><strong>Schema:</strong> update to v46.0.0-at-waiver-date-hotfix (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2e4bc0f0b57e6e4b4b68a63dcdbdc892089a6979">2e4bc0f</a>)</li>
<li><strong>Schema:</strong> update to v47.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ba02b136342ffee7502e0cf5a808693ceb78efca">ba02b13</a>)</li>
<li><strong>Schema:</strong> update to v47.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/716cc867193cd0d132d843016438cd7df678bb14">716cc86</a>)</li>
<li><strong>Schema:</strong> update to v47.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c8f53838d22286cdec8f94898b4cce6371045cd6">c8f5383</a>)</li>
<li><strong>Schema:</strong> update to v48.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c60610f58900246cef9b32faa037285d17b4bba8">c60610f</a>)</li>
<li><strong>Schema:</strong> update to v48.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3c6e7090558e707ec56ecd298a009fd21e263e05">3c6e709</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v47.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/23308c40163a704d2dd9f7df9fbd3037b849d662">23308c4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.5.8...v31.5.9">31.5.9</a> (2024-12-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>limited life:</strong> fixed export, adjusted column widths on pdf (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/66ff66ce99a1f6ae8d36387e7213540f8aee1ac2">66ff66c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.5.7...v31.5.8">31.5.8</a> (2024-12-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Inventory Transactions:</strong> added careated date, fixed export to pdf (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b6b89493e33173f8c6429be2e452f9e7f510dd2b">b6b8949</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.5.6...v31.5.7">31.5.7</a> (2024-12-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Initial Receipt Condensed:</strong> adding required indicator for destination org (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6e9af1dfbd4e96db93b0f7a85125d39c1e9c9bf8">6e9af1d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.5.5...v31.5.6">31.5.6</a> (2024-12-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Item Master:</strong> update fixes the tabs limited life and calibration (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f65e8584d06c05d4455dbf1d2f58f56af67fabb4">f65e858</a>)</li>
<li><strong>Schema:</strong> update to v46.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fb4dfd218f2d931f8244ab32b6017e595e2a0516">fb4dfd2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.5.4...v31.5.5">31.5.5</a> (2024-11-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Adding more inventory:</strong> now works as expected (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ee37d17f6d6a0605e402255b8fd8b9e3a3a7499b">ee37d17</a>)</li>
<li><strong>COSMIC Assemblies:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/92f34dd5ea865d8d378f06d0cf7f7e2e1ac5fa96">92f34dd</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/74275573987e739374a6a5adf2a8fd1a96d0d163">7427557</a>)</li>
<li><strong>Initial Receipt:</strong> fixed Item Drawing selector (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/13b0c778040cc9125930e43bd01415aaa8697d66">13b0c77</a>)</li>
<li><strong>Schema:</strong> update to v45.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5da9182a2f747e3c358303244f99f61086ac8f05">5da9182</a>)</li>
<li><strong>Schema:</strong> update to v45.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/536bfca9e152d9e3bf204686673405ddfefbd5cb">536bfca</a>)</li>
<li><strong>Schema:</strong> update to v45.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f94d243a5691884287bdf9b38b8335a32f64ddea">f94d243</a>)</li>
<li><strong>Schema:</strong> update to v45.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cafdc09b4eabfc74f52aaef822c188c9a70e792e">cafdc09</a>)</li>
<li><strong>Schema:</strong> update to v45.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/63e68065c1a9ccba8645a3250d5764ab30b54392">63e6806</a>)</li>
<li><strong>Schema:</strong> update to v45.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/85fa0c0e351779ad6b1b8b8279d5217159bf8c81">85fa0c0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.5.3...v31.5.4">31.5.4</a> (2024-11-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Document search:</strong> update back to previous old problematic version (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ffacf3426f382aae3b2fa538c8ee836935f748bc">ffacf34</a>)</li>
<li><strong>Schema:</strong> update to v45.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/64351a00e2937e59d4fc782b0c2250c6448e7536">64351a0</a>)</li>
<li><strong>Schema:</strong> update to v45.0.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7d682d22f882dc35ec35a58cabad5e42afe7b544">7d682d2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.5.2...v31.5.3">31.5.3</a> (2024-11-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ec35d4818ea8fdac53d43bb50cfa01025e2bc35e">ec35d48</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.5.1...v31.5.2">31.5.2</a> (2024-11-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/868682b7c7cbf16061e10c8f17dcc29fd2e83f98">868682b</a>)</li>
<li><strong>ItemInstance:</strong> updated inventory tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/256214055b56545da5c2b1dd387b04cc223b1968">2562140</a>)</li>
<li><strong>Schema:</strong> update to v45.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f1c624aa45e3ee428270bd107ec87ee1481f701b">f1c624a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.5.0...v31.5.1">31.5.1</a> (2024-11-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Home:</strong> adding back inv due back and in transit tables (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b433471288fd73653adbfa9be158246d3759eccd">b433471</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.4.1...v31.5.0">31.5.0</a> (2024-11-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/65443152e8af193b81cea0458a08cf95dd35b60c">6544315</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Home:</strong> update home page with hardware search (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/391828184f519ebaac6b13cc4779180e2ab5e925">3918281</a>)</li>
<li><strong>Schema:</strong> update to v45.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f268cd45c1174bce270b8f9825dd20055ce74390">f268cd4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.4.0...v31.4.1">31.4.1</a> (2024-11-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Nav:</strong> reset nav items to old links (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/177ecdd15e49f5b8f9a8f3a496e74d697f939cfe">177ecdd</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.3.2...v31.4.0">31.4.0</a> (2024-11-12)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2e9fe790d89c7b1e36df520b5caeb6a90804ab21">2e9fe79</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v44.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b0f5abd8078c90bd9045ff86b8ede971ac2943d2">b0f5abd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.3.1...v31.3.2">31.3.2</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/98ee87e9a9647faec6c8d9de1a97e53dfba37f41">98ee87e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.3.0...v31.3.1">31.3.1</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a8773e34bda7f3fd72aba6c625274b86482680f7">a8773e3</a>)</li>
<li><strong>Cycle Life Report:</strong> updated description row on export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/03ff0c3dd5717d0592692909cab2430419c34d5d">03ff0c3</a>)</li>
<li><strong>IRC:</strong> defaulting quantity to 1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1bdd3f35f28d0aa69722ed1018341c4d9fc92b66">1bdd3f3</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.2.2...v31.3.0">31.3.0</a> (2024-11-08)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> update to the Item Instance display (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/303ce3ec250ea664a3269c996f444b1896a789fe">303ce3e</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Search modifications:</strong> Hardware search, Locations search, Tracking documents searches all updated to include caching and new layout (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6b4498d723ba3679927fe3af30904765c1daa72e">6b4498d</a>)</li>
<li><strong>Search:</strong> new update to some searches to better reflect how the users use it (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/274b1b0d81bbb96bf541c861161747b7680615b2">274b1b0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.2.1...v31.2.2">31.2.2</a> (2024-11-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>911 tag:</strong> fixed column width on 911 export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/495f58763646106a01f968c845b0a85951628c90">495f587</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/13212a3cb9479eb2a4774a56b43bf308f7603b6d">13212a3</a>)</li>
<li><strong>Schema:</strong> update to v43.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/69a34b98172c141520de894d8a2427dc9707883e">69a34b9</a>)</li>
<li><strong>Schema:</strong> update to v43.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/91660bc6f5b3286e044dda9c4e6a6ce127d5f89d">91660bc</a>)</li>
<li><strong>Schema:</strong> update to v43.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cadd24cb4ec092cc4a0bed83433345b22755b6d2">cadd24c</a>)</li>
<li><strong>Schema:</strong> update to v43.3.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/82ef032cbe82ff65a665c34aa399d9576fd102cd">82ef032</a>)</li>
<li><strong>Schema:</strong> update to v43.3.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d982821713ad67d776536e071002ea4bc441e966">d982821</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.2.0...v31.2.1">31.2.1</a> (2024-11-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Code cleanup:</strong> moved code (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d48bc710729b596a6dec60e656376fa993852f42">d48bc71</a>)</li>
<li><strong>Schema:</strong> update to v43.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/479394d8674edea0e4289157b771a336947ff40d">479394d</a>)</li>
<li><strong>Schema:</strong> update to v43.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e62ed0ea7cdc1712c951ce13282167c9e7b0e852">e62ed0e</a>)</li>
<li><strong>Schema:</strong> update to v43.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/df0aa9a03df55448519b13ac2cc2144e1dd8e1c5">df0aa9a</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.1.2...v31.2.0">31.2.0</a> (2024-10-30)</h1>
<h3>Features</h3>
<ul>
<li><strong>Cache:</strong> implementing a thin client side caching rube goldberg (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/171c5112baa5fda02150140bdcf30a568552fec1">171c511</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.1.1...v31.1.2">31.1.2</a> (2024-10-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Active Project Codes:</strong> fix building number display and icon button color (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/24b0a5663fcb91f5d555295d606785b735632cde">24b0a56</a>)</li>
<li><strong>Assembly Comparison:</strong> updated searches for report input (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e984d123e143135deba6a0a6007435397bbc1d55">e984d12</a>)</li>
<li><strong>List templates:</strong> corrects issue where search would get stuck thinking about doing the thing but not actually doing the thing. We have corrected his thinking and you may proceed. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0bd7e63f29ffeb8ef69c5242a8deb75c0baf734d">0bd7e63</a>)</li>
<li><strong>Login:</strong> updated redirect on login (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/447d55746ce21e57eba4f320132299c9652ceca9">447d557</a>)</li>
<li><strong>Schema:</strong> update to v43.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6a4b96ef422d37032b1792f14484d287006e2a71">6a4b96e</a>)</li>
<li><strong>Schema:</strong> update to v43.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/01f6ea0a84250e495d67b3e40def98da23b08339">01f6ea0</a>)</li>
<li><strong>Side nav:</strong> fix active navigation icon colors (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ddb3f0755d59eaf2a6fc20f897580a6722ce3b87">ddb3f07</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.1.0...v31.1.1">31.1.1</a> (2024-10-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Http dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/280aeabecef4f7bd553beb6c923d087939ad2a3e">280aeab</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v31.0.0...v31.1.0">31.1.0</a> (2024-10-23)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v43.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8ed971df3ebb3a1fbb03733932bdb9f883ea9dd3">8ed971d</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.3.4...v31.0.0">31.0.0</a> (2024-10-22)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/50d1421b6802109a0ebb23f192e1538d53f977a4">50d1421</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Equipment groups:</strong> updated so each context can have their own equipment groups (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/51a577b9547a406a0e387a36ccbdc8686e9ca6d7">51a577b</a>)</li>
<li><strong>Schema:</strong> update to v43.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/119ef1511ef070233997eb5111ca39c7a8571694">119ef15</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> Hardware list changes</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.3.3...v30.3.4">30.3.4</a> (2024-10-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/397cfd492850004ba60e325d7c518873b96fbeb6">397cfd4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.3.2...v30.3.3">30.3.3</a> (2024-10-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Comments:</strong> fixes for displaying comments across hardware levels (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f5b4a40df54960c40603ebbbb753554802e24c26">f5b4a40</a>)</li>
<li><strong>COSMIC:</strong> Transactions and AHD updates. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4034aaf698c0ff96b8ab3aae5d31baf71c6181b4">4034aaf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.3.1...v30.3.2">30.3.2</a> (2024-10-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> updating auth domains (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e379dcc7680ff288ac5feece5fef3c76173c651f">e379dcc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.3.0...v30.3.1">30.3.1</a> (2024-10-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5124a6e97a6fe2bee1d71f6173c4438867f156d5">5124a6e</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.2.4...v30.3.0">30.3.0</a> (2024-10-09)</h1>
<h3>Features</h3>
<ul>
<li><strong>Comments:</strong> updated to use API v2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/181f9043ff3f74945f1e70f0f2a4ef22b48ccd4d">181f904</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.2.3...v30.2.4">30.2.4</a> (2024-10-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/115934643cd39e8e66ddfb518a6f4b7522fbe271">1159346</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.2.2...v30.2.3">30.2.3</a> (2024-10-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e0b0ee12d665c78b5023e1d50255c988a9feb18d">e0b0ee1</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/82a0594d05297754617512a548a2f1ad0e4f4dae">82a0594</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ca5dc5188b2f5a108bb2bc302faf2058bd6410b8">ca5dc51</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/43c87e37af4996a7020bde49087d51da564eaa89">43c87e3</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ee3127937174245f53f043ca5b4cb22162c1cf3f">ee31279</a>)</li>
<li><strong>Docs:</strong> enabling override date section (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5ed1b6b0187cc7d404de1dcd5d6f5c1d7bb03f36">5ed1b6b</a>)</li>
<li><strong>Reports:</strong> update transaction and change log reports (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a8113aea0fbb068382ac811be815b3cb14fa55fd">a8113ae</a>)</li>
<li><strong>Schema:</strong> update to v41.6.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b0e70385a2379034bf26da423008a352941210b6">b0e7038</a>)</li>
<li><strong>Schema:</strong> update to v41.6.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/416ebdf568966256012887b8ba359abf91d675eb">416ebdf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.2.1...v30.2.2">30.2.2</a> (2024-10-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c6b4425868b73ea7fff7f4ad081854283627ea22">c6b4425</a>)</li>
<li><strong>Hardware:</strong> streamline offerings (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8e8bae2c4fecfec6c0d55c1b48d227c142afea51">8e8bae2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.2.0...v30.2.1">30.2.1</a> (2024-10-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/11161adb67ea744ce9478f09dddfe163eb71a268">11161ad</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/38c9b7f6fcea64625803fd0536379299723e6710">38c9b7f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.1.4...v30.2.0">30.2.0</a> (2024-10-01)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7ba7433e85d57ee37e558a191dd1d8c534759063">7ba7433</a>)</li>
<li><strong>Inventory transaction report:</strong> update II link to use id rather than nodeId (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bbd509b22bb3ea4d2f51e614332fb3730f3c066f">bbd509b</a>)</li>
<li><strong>InventoryTransactions:</strong> update date time columns to date columns (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0c3be206e488b6ae46de5ea0045c9c32c530833d">0c3be20</a>)</li>
<li><strong>Item drawing manage:</strong> aliases now updatable (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a4843ae6247b302eda258b28a61c34b24644cec1">a4843ae</a>)</li>
<li><strong>Item master flags:</strong> corrected inheritance issue (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/59f241ae5092a43331dd58a17d9fe240ceadafb5">59f241a</a>)</li>
<li><strong>Receive Inventory:</strong> updated redirect url to the item instance manage page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a5466832e7f2c43908079cdbdbdcea3e0ce1cd08">a546683</a>)</li>
<li><strong>Schema:</strong> update to v41.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/733bd003a736e5607f149619f16fb83bc27f8be9">733bd00</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v41.6.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/722b5d49e6aab520cf3be38bd0ba053f464d1b87">722b5d4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.1.3...v30.1.4">30.1.4</a> (2024-09-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Item master:</strong> corrected issue with tabs if the hw was GSE (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/66f092c9926bd55ff2dcb3d0e3717401d85899a6">66f092c</a>)</li>
<li><strong>New Context:</strong> updates from demoing new contexts (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0706afe3c662eeb512c0571a09ebc5d926fc4e6c">0706afe</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.1.2...v30.1.3">30.1.3</a> (2024-09-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1295ce650bde9e773d92702d9215942b294b948b">1295ce6</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9b2a5486803f21a60f32d1e904fa3861cbfd6295">9b2a548</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/54d9a113d1c4e2c450daa20f60e6cdbf2b465c72">54d9a11</a>)</li>
<li><strong>HardwareMovement:</strong> Issue Out Destination label change (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/74ebad273702c46c0bab5478895d8921267cc45b">74ebad2</a>)</li>
<li><strong>Identification change:</strong> update fields (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0dfa77de1a3c2d927d9b5cfd3d8279beead48871">0dfa77d</a>)</li>
<li><strong>Schema:</strong> update to v41.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6da9262f19f65c0036bd1124d9b7024cdc9cca9b">6da9262</a>)</li>
<li><strong>Schema:</strong> update to v41.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f252a365d2a676035a3b10ea8bdfac37e5f71a37">f252a36</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.1.1...v30.1.2">30.1.2</a> (2024-09-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Org create:</strong> correct issue with subType (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0ae12f4cf18704c8f63df18ddd785ea2ab528461">0ae12f4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.1.0...v30.1.1">30.1.1</a> (2024-09-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/062bb858ca50a570c4e6ff8b7c911112a6c9fc37">062bb85</a>)</li>
<li><strong>Schema:</strong> update to v41.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d598027a88923dc55b0bc986a0f01ef4dafa6b5f">d598027</a>)</li>
<li><strong>Schema:</strong> update to v41.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b289b40170392f15734636325753a933eccfe96f">b289b40</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.0.2...v30.1.0">30.1.0</a> (2024-09-23)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Urls:</strong> adding url shims for item master and item instance links (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/60706a1c6a7f5788b1c514dc079d3e2c4bd1313f">60706a1</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Docker:</strong> update root node location (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/65adfacbdecb2945608b731977f7d57787488c9b">65adfac</a>)</li>
<li><strong>Docker:</strong> update root node location (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ed598ac4f76c35eb9e036e75c9698ce72e853ffb">ed598ac</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.0.1...v30.0.2">30.0.2</a> (2024-09-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Configuration update:</strong> corrects logic (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b024bb6a97441b6484a39e1ebf4ed59d17ac02e9">b024bb6</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/186c7f04b82b02bb99e45d6f63c3488d0e5ea028">186c7f0</a>)</li>
<li><strong>Item drawing:</strong> added new existence api when creating a new item drawing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/31245ae508446c6c8585f5e076c1f081ae47937d">31245ae</a>)</li>
<li><strong>Item masters:</strong> added new existence api when creating a new item master (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7f46ebaaa32a2d10fe9db4e3a51fbe4b7466b77b">7f46eba</a>)</li>
<li><strong>Org create:</strong> update detects existence by code and alerts the user (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/04270a5aab531d9602a8a8a9ab777efbfb0a2aa8">04270a5</a>)</li>
<li><strong>Organization:</strong> updated the create and added new admin tools (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/33e9b86614f1557f7476b59b5097caa42d6fe551">33e9b86</a>)</li>
<li><strong>Schema:</strong> update to v41.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/339ca6ba18476ec9cdde2fd46b4bbc1671f27ec0">339ca6b</a>)</li>
<li><strong>Schema:</strong> update to v41.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cde4527d658351fe033857910f05745be7e75376">cde4527</a>)</li>
<li><strong>Schema:</strong> update to v41.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/839b0a64881f762ccb4f02f74617d4b4990b1709">839b0a6</a>)</li>
<li><strong>Schema:</strong> update to v41.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/89abcc1552d9b67c835d7ec4cfc0e289318c85b3">89abcc1</a>)</li>
<li><strong>Schema:</strong> update to v41.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b649d32e5d98b69ec808b7cd540dbf143ae83675">b649d32</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v30.0.0...v30.0.1">30.0.1</a> (2024-09-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activities:</strong> corrected hardware related links (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/32c27adad7bc1179fe32101f396da5e5394cafd0">32c27ad</a>)</li>
<li><strong>COSMIC:</strong> Update brings new context online. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/300d995111df547895ab2d7923b1a3f1125574a5">300d995</a>)</li>
<li><strong>Hardware activity report:</strong> updated links (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0597fb082f22cb408d187eb40293c873096dc61a">0597fb0</a>)</li>
<li><strong>Hardware search:</strong> updated linkages to manage pages (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/616580495f947f78b4d6dc599dffc36e061c8781">6165804</a>)</li>
<li><strong>Item drawing:</strong> corrected redirect on creation (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0b03a92c97187e3673c3a13af9ea49bcf93e9cc5">0b03a92</a>)</li>
<li><strong>Limited life:</strong> update corrects LL form (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/51d1491e72cf4544fef903a35276adaafe325883">51d1491</a>)</li>
<li><strong>Links:</strong> correct links within item instance minis. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7bc7316e56b80deff6a8e63d7ceeb8807521759f">7bc7316</a>)</li>
<li><strong>Links:</strong> corrects links for item drawings and item masters. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/72e30d8eb2446e09181c74fd23e3e3a3f3214355">72e30d8</a>)</li>
<li><strong>Location manage:</strong> update links to hardware (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b61086dd39401075eac0679d7ad6c3d05f3ed464">b61086d</a>)</li>
<li><strong>Reporting:</strong> fix inventory transactions table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/480c1fdf353293c395bfe40a25e129ff4d19c2f1">480c1fd</a>)</li>
<li><strong>UI:</strong> improved date picker popover (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ced31a8aad4ae55ce3694ee7c5bd1735f1fb3ea9">ced31a8</a>)</li>
<li><strong>Upcoming approvals:</strong> update table to include actual data (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2277082ae3d51bb1c17fa7a58bd1d9aae14f9221">2277082</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.11.6...v30.0.0">30.0.0</a> (2024-09-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e682d53700539c15d064a4fa018d11d0193a25b8">e682d53</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8dc62e14e7fcaa9d1e044ce8ef2b04b6c730bd9f">8dc62e1</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ff3eee0e8107ef14d742fb43dfc2daae693c8c80">ff3eee0</a>)</li>
<li><strong>Reporting1489Summary:</strong> added contract to transactions query (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/50598e5a210f69c3526339bcf2923ba34d049d12">50598e5</a>)</li>
<li><strong>Schema:</strong> update to v41.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0cd7fe538e5eebb97d5b60d3eca30559026ea80a">0cd7fe5</a>)</li>
<li><strong>Support context:</strong> adding ES, ER, EV to the COSMIC family (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e850fc1017205c93ea3f47241bef62979c64d7ce">e850fc1</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Hardware:</strong> adjusting routes and switching to api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0b5358791484d1b206f4d95c814f9e6b930aed8a">0b53587</a>)</li>
<li><strong>Schema:</strong> update to v40.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/de63daa32799bd29d945ae609b3d966ec5b8b744">de63daa</a>)</li>
<li><strong>Schema:</strong> update to v41.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0c429007e3387dc48682f0d1222f6fd35ca0545a">0c42900</a>)</li>
<li><strong>UI:</strong> redesigned menus and new monospaced font (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/442ff85d81aa6dde14a7c02c554b9de684dc3b8b">442ff85</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Hardware:</strong> Urls have been updated!</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.11.5...v29.11.6">29.11.6</a> (2024-09-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5e18eaa1838cb6b05b873bf00a31b6fda734f1d3">5e18eaa</a>)</li>
<li><strong>Schema:</strong> update to v39.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c541ae39bac04b3094dce91c411590fbc17430e1">c541ae3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.11.4...v29.11.5">29.11.5</a> (2024-09-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/879e9c281faccac6526b56e5e608a307f80f514e">879e9c2</a>)</li>
<li><strong>Schema:</strong> update to v39.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/781cde0212c2f79a059e38c057c7b1e983994b17">781cde0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.11.3...v29.11.4">29.11.4</a> (2024-09-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FSC control:</strong> corrected ability to make it required (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bbc3bf8c0907b561f5b8c936d51396465bf32d4b">bbc3bf8</a>)</li>
<li><strong>Readiness report:</strong> updated colors on buttons to be actually visible (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/997bc585a4f857f000d51f4c5a4621ad11784809">997bc58</a>)</li>
<li><strong>Readiness report:</strong> updated the color of the icon in the actions column of the top table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/999c437b56e7b63664a29ba0a5e99a7054812b34">999c437</a>)</li>
<li><strong>Unit cost:</strong> update error state on control (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/152392a6a09cc6007c71ea35e2d826dc5a61933c">152392a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.11.2...v29.11.3">29.11.3</a> (2024-09-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bef9ce1a07045538aa831fa9f15c190df9ca804c">bef9ce1</a>)</li>
<li><strong>Initial receipt:</strong> update to fix wild beasts run amuck within the forrest of Evermore. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/11ea30e80dc279f42f41703e6110768566d6bf99">11ea30e</a>)</li>
<li><strong>Schema:</strong> update to v39.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7635b99a61d998c44795270e166d8a55b3250cab">7635b99</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.11.1...v29.11.2">29.11.2</a> (2024-09-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Permission:</strong> added permission for merging item instances (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/652d28561c99a9216f10f9875b4acde1d8fb622a">652d285</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.11.0...v29.11.1">29.11.1</a> (2024-09-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>User search:</strong> update to context related bug (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ca96b5a02df67e8a890eb6eb4be4a70f0d74d80e">ca96b5a</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.10.0...v29.11.0">29.11.0</a> (2024-09-04)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> streamlined checkbox controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/12831b46679c46bcffa23731dde2974f12266b9a">12831b4</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Item master/drawing:</strong> creates now use api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e149e2b2b11cd2211720045ff6e89ae4ccd56f32">e149e2b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.9.0...v29.10.0">29.10.0</a> (2024-09-04)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3c69818d9cc4a3a7b45ec13649a56cd8ebdde6d1">3c69818</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Item Drawing:</strong> switched creation to api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0712344e6f43e0ac6543737ba214ea17a8468b74">0712344</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.8.0...v29.9.0">29.9.0</a> (2024-08-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/17f5c9bf2a057e9861d3cf7471ab74540f9f26e8">17f5c9b</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/110fee77068f6aeaa17dd4dba95ffd969a8f363e">110fee7</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/48eb8a8ef283fc35c681eb0c6e1feb1a27400716">48eb8a8</a>)</li>
<li><strong>Inventory movement out:</strong> update screen (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/59856fc89059d29d0ab4080facf4b2c9c4c8c4c0">59856fc</a>)</li>
<li><strong>Inventory report:</strong> update tracks the main table and exports only what the user visually sees (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/dff7ad9e3b7511e3834bade3d7da82b3b948d1c3">dff7ad9</a>)</li>
<li><strong>Inventory Transactions Table:</strong> updated column widths (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8b7b4e0263d76ecbf51e3919b099c016f8d2347a">8b7b4e0</a>)</li>
<li><strong>Schema:</strong> update fixes breaks from v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1d2c15e398664c91f334167fe72d2df8864334c7">1d2c15e</a>)</li>
<li><strong>UI:</strong> streamlined radio button controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b1c16593adb2b05e2e7646d204c89343fc9aa204">b1c1659</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Barcode:</strong> experimental new view for ingesting data embedded into a barcode (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/dfa7dd9989451688461f2e437610d4fb31d1547a">dfa7dd9</a>)</li>
<li><strong>Inventory movement:</strong> updates many movement screens (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5247348ca4de906da94adcd6aebb135937d4c151">5247348</a>)</li>
<li><strong>Receive inventory:</strong> updated to new ux (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8ec9fbf2bf28a8c32de007e3d371e24f80d832e8">8ec9fbf</a>)</li>
<li><strong>Schema:</strong> update to v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1c574736d1db9fc90068523cf0563359223f6e72">1c57473</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.7.2...v29.8.0">29.8.0</a> (2024-08-27)</h1>
<h3>Features</h3>
<ul>
<li><strong>Org:</strong> create org fixed (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/12bdba27ab25f3e3d2544ba406f58f1178ffd92e">12bdba2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.7.1...v29.7.2">29.7.2</a> (2024-08-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/95dbd9ab6a044aa33a41edab6be0728579fe855f">95dbd9a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.7.0...v29.7.1">29.7.1</a> (2024-08-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Critical inv/Chicklet:</strong> moved to logistics (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b9d07a528d20b79ea6dba83789d42388d3840f7c">b9d07a5</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.6.3...v29.7.0">29.7.0</a> (2024-08-21)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assemblies:</strong> fix spacing on manage page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/98f9e35c9eed6414252a517308fa993d1b0cb084">98f9e35</a>)</li>
<li><strong>Hardware search:</strong> update to new standards (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d19c8ab37ad5ce1e8fa4dc66ba475816267afc8b">d19c8ab</a>)</li>
<li><strong>Item drawings:</strong> update manage (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8cda8806f1c19788fef4e41a92f49ffb90839e5b">8cda880</a>)</li>
<li><strong>Item instances:</strong> update manage (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a9a886ef4e6d2b93e4e2b5da1031973109489ded">a9a886e</a>)</li>
<li><strong>Item master:</strong> update tweaks on manage page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1ac496f169177500c554ceeb9177d73f6de44a1c">1ac496f</a>)</li>
<li><strong>Readiness Report:</strong> remove duplicate documents from results (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ba21725e3a8a2da728d31db0f5aa59b1a41182c1">ba21725</a>)</li>
<li><strong>Search views:</strong> seminal moment for search pages (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8c9c93494ac27fad1c7616c0ae9097f6f8e18a00">8c9c934</a>)</li>
<li><strong>Side nav:</strong> corrected issue where if the user was on hardware search the icon would disappear (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/dd8e7b448301badd9b9c797c5556e804888f43d0">dd8e7b4</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Modal:</strong> implementing new webcomponent backed modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7f5a3dd9d3ff4f168d60dc936e02289c210919c8">7f5a3dd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.6.2...v29.6.3">29.6.3</a> (2024-08-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c58add9ae5e405290ed226794a7494a8492bad10">c58add9</a>)</li>
<li><strong>Create view:</strong> update brings a new design standard with regulatory compliance (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/172aff5cf6f523c75e171d90401ecfc352e86b0a">172aff5</a>)</li>
<li><strong>UI:</strong> fixed selection control and made spacing adjustments (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/274dc560596ad6f20d818a9b6627e073c6dbb5e4">274dc56</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.6.1...v29.6.2">29.6.2</a> (2024-08-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v38.4.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3a312b122a2a28cc25fae68f78cc227a3470df52">3a312b1</a>)</li>
<li><strong>Schema:</strong> update to v38.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e3c0012a2a64e8d34d8db6cbb9d563b47568a78f">e3c0012</a>)</li>
<li><strong>UsersContacts:</strong> updates to ux new standards (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/36ef080d47f79b01f9d3cbd66ed614990d9aeb19">36ef080</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.6.0...v29.6.1">29.6.1</a> (2024-08-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Contacts manage:</strong> update to new design (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9b856a466d0541e86c0a5d87f18e5d44044156e9">9b856a4</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.5.0...v29.6.0">29.6.0</a> (2024-08-13)</h1>
<h3>Features</h3>
<ul>
<li><strong>Admins view:</strong> update adds more application level details to a new admin tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6dc14f9d1428db61c5d2189ab759522908242ff6">6dc14f9</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.4.0...v29.5.0">29.5.0</a> (2024-08-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Reporting:</strong> fixed drawing number field on Inventory report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/09bdb61ad2d305c00a5741a2039550eff332174e">09bdb61</a>)</li>
<li><strong>Schema:</strong> update to v38.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a260587a713a7979cf4af4ef859a198f53cb8947">a260587</a>)</li>
<li><strong>Schema:</strong> update to v38.4.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7f41b4c170a9a467bffc4735a91ebb2297627b18">7f41b4c</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Tracking Document:</strong> update create to use the new format (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/975e1fc4cde08f2ad43d182d66a0ec9283b195e2">975e1fc</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.3.0...v29.4.0">29.4.0</a> (2024-08-07)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v38.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/25a3188ce194496ee82a769c9c89cf84147be84d">25a3188</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Betterment:</strong> Buttons have been standardized (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3247832cc2d0b44b3fa9ebd5b428d6b2ac12809b">3247832</a>)</li>
<li><strong>Betterment:</strong> icons are navigating to our control (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/88a50752c26cb221778c33737f884a3a980aac39">88a5075</a>)</li>
<li><strong>Betterment:</strong> icons are navigating to our control (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8eeed091f58edc683fd8a8aa05b61cb77485c00e">8eeed09</a>)</li>
<li><strong>Betterment:</strong> icons are navigating to our control (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b5250a70fbb0e4564b25c2d04f6810643889d0bf">b5250a7</a>)</li>
<li><strong>Betterment:</strong> icons are navigating to our controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/aff0474a38b928dc8557e2689f17e7871a823b22">aff0474</a>)</li>
<li><strong>Item master report:</strong> updated to use API2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/769b6288a427570de20340fd2220e7641fa27fa7">769b628</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.2.0...v29.3.0">29.3.0</a> (2024-08-05)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activity search:</strong> update standardizes clear all filter btn (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a11c336c338ab173ac601467deb9c35a82c2551a">a11c336</a>)</li>
<li><strong>Clearing selections:</strong> standardized this action into a single control for consistency across the application (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/96afc97225af5cd8fa8693de2be6c654d2e6be8e">96afc97</a>)</li>
<li><strong>Colors:</strong> update uses better color contrast for 508 compliance (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f5af1734fadee3c43a417bfb9120e76e11749572">f5af173</a>)</li>
<li><strong>Hardware search:</strong> update w/ new buttons (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ac24f720257e77c1aa314a700cb74edfe5143f8f">ac24f72</a>)</li>
<li><strong>Schema:</strong> update to v38.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2b9dc304add63e95391273053aa4e9a424ee7e4e">2b9dc30</a>)</li>
<li><strong>Side nav:</strong> now uses web components; functions the same (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f3ec920579e07e3f3e5284efeab9cd82a66cee58">f3ec920</a>)</li>
<li><strong>Tab Document Information:</strong> UX update to current standards (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2fda1b677b53b5bb64c6ca4a2696cf4df1d48312">2fda1b6</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Betterment:</strong> icons are navigating to our control (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/921bc0337b0673569e99f0d42573c034d907bd8f">921bc03</a>)</li>
<li><strong>Betterment:</strong> icons are navigating to our controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/22d6799bb559a576285aecb593f80bdcf1508c73">22d6799</a>)</li>
<li><strong>Betterment:</strong> icons are navigating to our controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f2cb7a92f60a8eca94896195bd42dfac0918b585">f2cb7a9</a>)</li>
<li><strong>Betterment:</strong> The app is objectively better today than it was yesterday. This is a scientifically verifiable fact that cannot be challenged or undermined. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9d970ec0b927071e4226680227e460a7a1c4024d">9d970ec</a>)</li>
<li><strong>COSMIC text input:</strong> initial rollout of new web component (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/326c2160c0670533532106fc5ac3768a7be8def3">326c216</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.1.0...v29.2.0">29.2.0</a> (2024-07-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>508:</strong> update adds more keyboard functionality to COSMIC (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6d9b02804aa7eff725379da5271ab2241c4e608c">6d9b028</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/64b6f589875128fbb90077c0acab31e646b4bbf8">64b6f58</a>)</li>
<li><strong>Deviations:</strong> update corrects API pathing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/23450878ed52e5dffc9197efeaa6e2139ec7b875">2345087</a>)</li>
<li><strong>Document Deviations:</strong> initial update to api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e3336b6b52fda30701ee333d81c608d595dd3c1e">e3336b6</a>)</li>
<li><strong>Schema:</strong> update to v38.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/473d8972280fc6d3c1d78023007cd90515900a93">473d897</a>)</li>
<li><strong>Schema:</strong> update to v38.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/63fdaeda056a7bc158976ed1c7e1131b66998d45">63fdaed</a>)</li>
<li><strong>Schema:</strong> update to v38.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8293e0b68cecbecd27e4ea72609cfbed70e824c0">8293e0b</a>)</li>
<li><strong>Schema:</strong> update to v38.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9dd87c81ba15b093af2b747e4a0f90001a7223e7">9dd87c8</a>)</li>
<li><strong>Standardization:</strong> implement icons in top nav as well as other wonderful augmentations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/53921e01701a0b1960eb1c81203ae5a4a68a3fa9">53921e0</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>UI:</strong> implemented new button web component (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b059787c1d04db0153d3fd7676c22f90f7a0d89a">b059787</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v29.0.0...v29.1.0">29.1.0</a> (2024-07-26)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>1489 Report:</strong> updated contract numbers display (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/794a7e5a364b35f6a1e5a1af8a07c29f2abf41e2">794a7e5</a>)</li>
<li><strong>UI:</strong> restored more familiar hardware icons and updated the edit icon (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/474bce6da943a2af4edcbea1e3be55baa25fe844">474bce6</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Authorization:</strong> overhauled the order of operations for authorization and authentication (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/59ef31f1595a0b118b4b5c8b050dafea55cefa1e">59ef31f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v28.0.4...v29.0.0">29.0.0</a> (2024-07-22)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Docs:</strong> updated projected date on line items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/232131e49de9a6d58d086367a88e735b6daeec50">232131e</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Icons:</strong> update to latest icons; previous icons were depricated (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1a672f4f6a96b337b817b1b270408c94faa6367d">1a672f4</a>)</li>
<li><strong>Schema:</strong> update to v38.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/50dfd14e17637db3a7c4d1bebcb1dc2b784f7e85">50dfd14</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> date/time update system wide</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v28.0.3...v28.0.4">28.0.4</a> (2024-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Build:</strong> correct build error preventing deployment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fa5475ee86c0fc331bfff0c972a451acfb8149fb">fa5475e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v28.0.2...v28.0.3">28.0.3</a> (2024-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2a13d1b3c86b185398fb41c47b87b0bf1e9ce218">2a13d1b</a>)</li>
<li><strong>MiniAssembly:</strong> reorganizing the ellipsis menu (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d8e56f7a8025a7fa91fe1c63f0fd1d1b789c2c60">d8e56f7</a>)</li>
<li><strong>SearchDocument:</strong> corrected width on the seq col of the results table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/532bae9bfba288164627fcc4311af5d07c911de5">532bae9</a>)</li>
<li><strong>SearchDocument:</strong> corrected width on the subType col of the results table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/85b51e3cafb0f456908ed9e24e55ebc64154e7d1">85b51e3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v28.0.1...v28.0.2">28.0.2</a> (2024-07-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assembly:</strong> Hiding redundant buttons and zebra-stripping list options (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/87553933557bbe91631bdc2b9ef70ece82fc2361">8755393</a>)</li>
<li><strong>Assembly:</strong> update ux for better visual wow-ness-es (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6873bb6d680cab613defcb5bf3a5abf625d50c12">6873bb6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v28.0.0...v28.0.1">28.0.1</a> (2024-07-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assembly:</strong> update adds linkages to popular reports (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c1dc6987c0f2cdd7e823b05158467b1daecc8bd9">c1dc698</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v27.0.8...v28.0.0">28.0.0</a> (2024-07-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activities:</strong> Update to use new API2 endpoint. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cdbd77739f66a3538a505d94cb66098b385f9cef">cdbd777</a>)</li>
<li><strong>Activity:</strong> removing activity report, use search (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/22dbf8ff2ef21c2ca1a2a0b5e22cf5013bd5a20f">22dbf8f</a>)</li>
<li><strong>Asset pathing:</strong> update to point back to the proxy (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/157f7159472d68686f42cbf62626e13269afa6ae">157f715</a>)</li>
<li><strong>Inventory quota:</strong> update report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/09894b118aec1e76e7705a873b60e05f40004137">09894b1</a>)</li>
<li><strong>Knowledge base:</strong> update url (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b84484376f620489f9906a70e32b571fa0bd1d94">b844843</a>)</li>
<li><strong>No activity report:</strong> update UI to modern standards (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f827cee506aa37b83d1ad5b86cd430d477b8a717">f827cee</a>)</li>
<li><strong>Schema:</strong> update to v36.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/27877eb24447b1cca6bb8b56224276fa8fe578e3">27877eb</a>)</li>
<li><strong>Schema:</strong> update to v37.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bae1a4c276cde99c9099d3ad0d6874b93d421d9b">bae1a4c</a>)</li>
<li><strong>tables:</strong> updated missing columns on various tables (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/94dfba677004e0c2c591d3f6f9fc826a706b3228">94dfba6</a>)</li>
<li><strong>Top nav:</strong> made calibration link go to results (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/35fc48747c28ef546b260044f3f390fa7e04ba6f">35fc487</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Event creation:</strong> allow vehicle attachment on launch and return event types; made location not a required field (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b9495ff84512d635f36771a901839ff4bb61e4b0">b9495ff</a>)</li>
<li><strong>Event manage:</strong> update includes new layout and the ability to attach Vehicles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2955ab1cbccff8ed5e2ced7b3e68e6c34ac21f5c">2955ab1</a>)</li>
<li><strong>Events manage:</strong> update ux for hardware lists (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/93ad6503ff5666f8345f768cd718e82c11343eaf">93ad650</a>)</li>
<li><strong>Reporting:</strong> improvements to 1489 Object Class pricing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d3d07f9420805f5802bf1aae8c34d60d66edd7bd">d3d07f9</a>)</li>
<li><strong>Schema:</strong> update to v36.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f7d87d21f03913ccd19342e9efaefe29dd8e8145">f7d87d2</a>)</li>
<li><strong>Tables:</strong> change to table styles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/560960f892a62ea9a82375599cb45f1dc1b9dafe">560960f</a>)</li>
<li><strong>Vehicles:</strong> new entity type (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/892639f2123dacba8e825a5457e33d03399b8f8e">892639f</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> column renames and date/time related updates</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v27.0.7...v27.0.8">27.0.8</a> (2024-06-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Asset path:</strong> Update GCP bucket url. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fb4d9f12ba0a5b58a4cc3e088523ad7907ff4687">fb4d9f1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v27.0.6...v27.0.7">27.0.7</a> (2024-06-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assemblies:</strong> attempt to limit db calls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/da7988222955b198f9b47782afe9f3704488dd8b">da79882</a>)</li>
<li><strong>Assemblies:</strong> corrects assembly/template node highlighting (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/416077bdddda8e41a8147c1ef7446ca26d7ca05f">416077b</a>)</li>
<li><strong>Assemblies:</strong> update brings hyper speed to assembly searching (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f0a55dd8bcc70780078dc3cb75d364ea1967d5a9">f0a55dd</a>)</li>
<li><strong>Assemblies:</strong> updated the highlight box that appears when you scroll past the fold (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5ef68d8b8ead4e5713695f8025c4619c0c5901ad">5ef68d8</a>)</li>
<li><strong>Assembly/Template:</strong> update modals to be a similar format (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5c8850904e7b89eaab6f2838b6b45a3806855a49">5c88509</a>)</li>
<li><strong>AssemblyTemplates:</strong> added support for highlighting by template name, dn, desc, asb, serial (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/256a6be0383c7e665d382383e98f25c16cd128bc">256a6be</a>)</li>
<li><strong>Assembly:</strong> when comparing dont offer to install if there is something installed (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/35db333bb68c8beb13471201af6d3d12e0c4c032">35db333</a>)</li>
<li><strong>Hardware Search:</strong> added note for location filters (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4b93d8ac520640adc363600b6c3c6789e6340565">4b93d8a</a>)</li>
<li><strong>MiniAssembly:</strong> added support for highlighting by dn, desc, asb, serial, lot (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4bc768257925f791252f23d17ee651c2157152bb">4bc7682</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v27.0.5...v27.0.6">27.0.6</a> (2024-06-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Assembly dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/054ef9736b2942555da31a9c69bf32a84a0aaa6e">054ef97</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v27.0.4...v27.0.5">27.0.5</a> (2024-06-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Navbar:</strong> Update navbar accessibility for icon buttons (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cee3f942e4782532bb8ded641e66ab1b3c75be43">cee3f94</a>)</li>
<li><strong>PhysicalInventoryAuditsManage:</strong> Update closed date pill to be monospaced and show the date time display. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ce28a594f347ac89c19e19ce1295582e35ba93c1">ce28a59</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v27.0.3...v27.0.4">27.0.4</a> (2024-06-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assembly:</strong> can edit (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8032399013151edcebbc07c7b2a776dd292074fe">8032399</a>)</li>
<li><strong>Pathing:</strong> update cosmic paths (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/004171bfe11371fa7313937e65b4ffe5c68de4fd">004171b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v27.0.2...v27.0.3">27.0.3</a> (2024-06-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assembly:</strong> update related to minis and manage pages (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2d4f4f8f54c97fa547c62a187e01c11525935ac7">2d4f4f8</a>)</li>
<li><strong>Assembly:</strong> update visible errors (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3cdf080125b3a93296f1c33b1de0d313e0bbeeda">3cdf080</a>)</li>
<li><strong>Assembly:</strong> updated overly (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bd1ca76333bbb059ec41732962aa1e549305a5de">bd1ca76</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v27.0.1...v27.0.2">27.0.2</a> (2024-06-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assembly comparison:</strong> update corrects error when comparing assembly to a template (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/92b05dea698e3503c093aa2075826ae68ef4adb7">92b05de</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v27.0.0...v27.0.1">27.0.1</a> (2024-06-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>outages:</strong> updated banner height (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bce2b408bce91c96a9497f7d14c9b654f0dc92c3">bce2b40</a>)</li>
<li><strong>Schema:</strong> update to v35.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1253b7649a397119b161ce3857f6a1d16a813ddc">1253b76</a>)</li>
<li><strong>Schema:</strong> update to v35.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ff0bea851658d35550f08e477c16b11ed111c3a3">ff0bea8</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v26.1.4...v27.0.0">27.0.0</a> (2024-06-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ActiveProjectCodesReport:</strong> migrate active project codes report from gql to api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/726ec7bbe011726fb9705705cf8a37bddf40e4fe">726ec7b</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v35.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/045f6d25996f981cda3e856c7fe4d35b0d3e39d9">045f6d2</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> createdDate/updatedDate rename</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v26.1.3...v26.1.4">26.1.4</a> (2024-06-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bd51f278b11603defc6926bf41eaad5e6f4504ac">bd51f27</a>)</li>
<li><strong>Schema:</strong> update to v34.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f1ba8f7cc3c202b669c7184f6773921854793b6d">f1ba8f7</a>)</li>
<li><strong>Schema:</strong> update to v34.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/93aab5d8bc981d1b2a1a9fb3b82dd8dd503e8148">93aab5d</a>)</li>
<li><strong>Schema:</strong> update to v34.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6ac9207140834e2e31246b3e76246a08c56b3d3a">6ac9207</a>)</li>
<li><strong>Static assets:</strong> update pathing per environment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/51a68886b4d23eee88db545c27b03b8689da0a56">51a6888</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v26.1.2...v26.1.3">26.1.3</a> (2024-06-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f9e695d82f74a7fd4de3e0b10ecb18749a632fc3">f9e695d</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2ab8ce05f531e0fb27b1cc171ec538c846e842d1">2ab8ce0</a>)</li>
<li><strong>Schema:</strong> update to v34.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a1ae241a154cc57d19f1fbc669aa7bfba73589a5">a1ae241</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v26.1.1...v26.1.2">26.1.2</a> (2024-06-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/531b40e5ed0f833555ce82faa38e102cb649f975">531b40e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v26.1.0...v26.1.1">26.1.1</a> (2024-06-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e5ba12727399f9f02431487cc3f9f3775735d770">e5ba127</a>)</li>
<li><strong>Schema:</strong> update to v34.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6a037bfd6b953d715249c36ad460db5d7fd133cb">6a037bf</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v26.0.2...v26.1.0">26.1.0</a> (2024-06-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ItemMaster creation:</strong> adding context and UI improvements (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3538d299a27ee84aa728771ad48bea32f7c3af12">3538d29</a>)</li>
<li><strong>Schema:</strong> update to v32.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ce2a143c57422950b084d345377b771cb2189dd8">ce2a143</a>)</li>
<li><strong>Schema:</strong> update to v33.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ebc44d053807e19f1bdd4c066fb8b40c11fb02f7">ebc44d0</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Dates:</strong> attempt at fixing datetimes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b7fb5e55e8faad4939a5fe21e9e37a3062a9ff5c">b7fb5e5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v26.0.1...v26.0.2">26.0.2</a> (2024-06-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Misc:</strong> update BUCKET_PATH variable (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/673587fb2dd7da72ecd0556902376cd8f50f0ae3">673587f</a>)</li>
<li><strong>Top pages:</strong> update favicon url (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d49e77c7e0aeb175c38d345aa3b61eb7a984000c">d49e77c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v26.0.0...v26.0.1">26.0.1</a> (2024-06-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Misc:</strong> update image pathing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cb1836a0bdbfaff5fb21e1880359dec346e92580">cb1836a</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v25.1.0...v26.0.0">26.0.0</a> (2024-06-10)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Item Instance:</strong> made report buttons consistent (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/dfee8266ba956b4e028033ed20063ed4a146d3bf">dfee826</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Static resource mgmt:</strong> change implements current best practices for serving static content (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d8267821b41cd9cb2c0f89aa66a1cd82ceba066b">d826782</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Static resource mgmt:</strong> images could need to be remapped</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v25.0.6...v25.1.0">25.1.0</a> (2024-06-07)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9985c17ff65f5b31bb8da7d2a9db892e673755e8">9985c17</a>)</li>
<li><strong>Schema:</strong> update to v31.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b760ebcd29ba099eaad8b8225c021eef0794707d">b760ebc</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Date:</strong> Date control now has a date picker (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ebd72984c11f6d72d9ac6da2fefd8193a73a8d2a">ebd7298</a>)</li>
<li><strong>Schema:</strong> update to v32.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d895ba77966fbeed4f76e78e5053051d57fb0875">d895ba7</a>)</li>
<li><strong>Schema:</strong> update to v32.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c91758520fb529c69c146c2fa95490f6356312cc">c917585</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v25.0.5...v25.0.6">25.0.6</a> (2024-06-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ChangeSets:</strong> Remove all references to Access Requests. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b0a265143d959b17ac128cc6fc7bd3fb8f35d7f0">b0a2651</a>)</li>
<li><strong>FoureenEightyNineSummary:</strong> Report improvements and standardization. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/513f28ab0401c3c1a71614772baacba90aeac649">513f28a</a>)</li>
<li><strong>Schema:</strong> update to v31.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/31bcff06e0fca7797e12ae40a06561c3a2b6b817">31bcff0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v25.0.4...v25.0.5">25.0.5</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9882e5c43643abe00d6f3b54e6fbf39dc94da5bf">9882e5c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v25.0.3...v25.0.4">25.0.4</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EquipmentControlNumberReport:</strong> Show drawing number on results table. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a94e5cddf9a31a68d9d3576b073ea3a535dc94e3">a94e5cd</a>)</li>
<li><strong>Hardware list:</strong> moved the component into nasaUI (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1d272641381741fcc06d37973056c11ec754e314">1d27264</a>)</li>
<li><strong>HardwareSearch:</strong> Update search params to match API2. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0b3c2b44fdf89224ab15fddeac16ebe699c17175">0b3c2b4</a>)</li>
<li><strong>InitialReceiptCondensed:</strong> Allow creation of new Item Master with default as built when there are existing as builts. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a09d3b625ee01ef9ce7eddcfec5d4a7bc4a60d04">a09d3b6</a>)</li>
<li><strong>Schema:</strong> update to v31.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5d738e401fc90e3498b43aaef58ed1183b499dbe">5d738e4</a>)</li>
<li><strong>Schema:</strong> update to v31.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6a024ca3102f1893e591a5c7b565aea00023062c">6a024ca</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v25.0.2...v25.0.3">25.0.3</a> (2024-05-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3284b391a5db102b734d91e824c2dddb7aa2d4f3">3284b39</a>)</li>
<li><strong>Schema:</strong> update to v31.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b354c026491dd8d5ed717777e34b139e00f726a0">b354c02</a>)</li>
<li><strong>Schema:</strong> update to v31.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/59764f6d8fd36d4c2b5829821e9e8a1cdb8612af">59764f6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v25.0.1...v25.0.2">25.0.2</a> (2024-05-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v30.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/228e9792962235ba4e3d725d64031626a1dad22e">228e979</a>)</li>
<li><strong>Schema:</strong> update to v30.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8a4d106934f8010e17e7800f7ecf80dbc2143f55">8a4d106</a>)</li>
<li><strong>Schema:</strong> update to v30.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1c6888cb9491d188f791c88ca853cdf5bdbaa5e5">1c6888c</a>)</li>
<li><strong>Schema:</strong> update to v30.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/77c55819c33d4b7500157252773327cbd322889a">77c5581</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v25.0.0...v25.0.1">25.0.1</a> (2024-05-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>1489, NoActivity and 1324:</strong> Use new API2 enpoints. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b78e1f0217c0878c9c60fcfaac69715c750fdef8">b78e1f0</a>)</li>
<li><strong>HardwareActivityReport:</strong> Load item when id passed in url and fix export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ea37b8d143bc50350071cece041f31bc8f43307a">ea37b8d</a>)</li>
<li><strong>HardwareActivityReport:</strong> Update results table to use new actions table instead of expandable row. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b3048865c008d8dbdc045eb60e6ea7872e606bf1">b304886</a>)</li>
<li><strong>Pagination:</strong> Standardize table pagination to use constants. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9aa69cc79bfc5b5e9cc20d3b507f7712204b392f">9aa69cc</a>)</li>
<li><strong>Schema:</strong> update to v30.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/49d4b0908374dc22a282ab50c12dffc184d75ffc">49d4b09</a>)</li>
<li><strong>Schema:</strong> update to v30.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/936b9dcf8d29d29a2ecebef44b62c5c13fb68820">936b9dc</a>)</li>
<li><strong>Schema:</strong> update to v30.1.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/67503963fc432a18b123904f1e23d3019896b830">6750396</a>)</li>
<li><strong>Schema:</strong> update to v30.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f2616f9004ec59bc5397be16455bb167937231e3">f2616f9</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v24.0.0...v25.0.0">25.0.0</a> (2024-05-09)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Cycle Life:</strong> loading preselected item instance (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0fbab33177e81ba6424c91815f8a6b410b3415b7">0fbab33</a>)</li>
<li><strong>Hardware dates:</strong> added inline clear for selected hardware (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a1214447c927484c5181e1500c19b3363a2061d7">a121444</a>)</li>
<li><strong>HardwareComments:</strong> Delete unused report. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7765dd886ebe41337330da4b964cf3f0553593ff">7765dd8</a>)</li>
<li><strong>IRC Condensed:</strong> Change field order to drawing number, as built then description. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b524d4bbf8b5697ac4c1f8266ae100cb2d9079f5">b524d4b</a>)</li>
<li><strong>Schema:</strong> update to v30.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/93c5fc2cda49446143a9be5283f84085ef16621a">93c5fc2</a>)</li>
<li><strong>Schema:</strong> update to v30.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/14560cd28d56d3a4709245b71670bdaa9f42dd70">14560cd</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v29.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/463d45a46c508cd7c874a71892e2ce6cd276857b">463d45a</a>)</li>
<li><strong>Schema:</strong> update to v30.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e63fc02216389d8f1a5169c782a8ba5185f38631">e63fc02</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> no user facing updates massive typings changes</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v23.0.5...v24.0.0">24.0.0</a> (2024-04-29)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6d68fd0b389a9eeb7d07753cc2a07b4cfcef6d02">6d68fd0</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Types:</strong> update includes adding submodule access for api types (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/da877b16d4c9a4ba6f69ca76a7a8c5231aab90d3">da877b1</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Types:</strong> schema updates</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v23.0.4...v23.0.5">23.0.5</a> (2024-04-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> Update to 27.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/16b0ac2a695e55dee259359a2794753859358177">16b0ac2</a>)</li>
<li><strong>Schema:</strong> Update to 28.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d965a2855b726a8ca94eba6299d34b60f569db81">d965a28</a>)</li>
<li><strong>Schema:</strong> update to v27.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1b39bc337ea67bd397510c531b90ebcf1e311319">1b39bc3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v23.0.3...v23.0.4">23.0.4</a> (2024-04-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li>Dockerfile artifact registry project (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c958b954072daa1d0b32dd4405b311d113ba586c">c958b95</a>)</li>
<li><strong>Schema:</strong> update to v27.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f510947421e755cf05ed7f52480b094223bd6abb">f510947</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v23.0.2...v23.0.3">23.0.3</a> (2024-04-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f0a1be86a6e61893b631421ae616604be65fc5b7">f0a1be8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v23.0.1...v23.0.2">23.0.2</a> (2024-04-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/345bf8ddb0e9c0d72f50f4d3c49ba9c2f02f5a7c">345bf8d</a>)</li>
<li><strong>Reports:</strong> Updated column definitions for reports with blank exports. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f54a11c6c6b9b9c4dd6549f4082f12d5ad49683b">f54a11c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v23.0.0...v23.0.1">23.0.1</a> (2024-04-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/092c7a608595889d86eb87f22f2759792bfe5499">092c7a6</a>)</li>
<li><strong>Schema:</strong> update to v27.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8db284fe97af26c7e0bea7d65d201b01230af14f">8db284f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v22.1.1...v23.0.0">23.0.0</a> (2024-04-16)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v25.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/675901fd8eb00d745c5693762d7f2cc8753d924f">675901f</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v26.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/58c635eaeeec8c65d8ff5d8f7b073fdd01919166">58c635e</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> No visual changes.</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v22.1.0...v22.1.1">22.1.1</a> (2024-04-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Events:</strong> updated read only check on events manage (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c587268e82f3f494e9d43fe05b0bdf070648c3da">c587268</a>)</li>
<li><strong>User:</strong> update call to work when there isnt an auid (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c56ddcd77827468814c23324269c7285535469ff">c56ddcd</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v22.0.2...v22.1.0">22.1.0</a> (2024-04-12)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Historical Records:</strong> The share link is fixed (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ebe7d87ad4c496304c42835c64cb61d4d9e05a98">ebe7d87</a>)</li>
<li><strong>InventoryByBin:</strong> updated query to reduce search time (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/844e29c8bc8aaee66c2f7555485c1d9f9fbc5416">844e29c</a>)</li>
<li><strong>InventoryByOrg:</strong> update loader after fetching presets (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bb8b2706421d1d2fb9e8fc7fb72f427ba5c66f8d">bb8b270</a>)</li>
<li><strong>Outages:</strong> updated outage banner (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c253c15c0cea528c4ecdaf72c1849cfd40724a47">c253c15</a>)</li>
<li><strong>Return Inventory:</strong> moved title, updated wrapper thickness (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c4a0c88d6418466144238228cd251d80fdbb8391">c4a0c88</a>)</li>
<li><strong>Schema:</strong> update to v20.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ac9d19d746cde024af7bdeaa7855fa1584d58bd7">ac9d19d</a>)</li>
<li><strong>Schema:</strong> update to v20.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/81b6658542fb54378e4ab773d9f41d041f326f65">81b6658</a>)</li>
<li><strong>Schema:</strong> update to v20.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ab745a8fbaba22a6e0e56178a8a5776a0efd787b">ab745a8</a>)</li>
<li><strong>Schema:</strong> update to v20.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/514ed303c08de35fe920224b66bc9898209ab729">514ed30</a>)</li>
<li><strong>Schema:</strong> update to v20.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1ef61fd13c06cfb0d5c544e1046f7f9560bbf5e6">1ef61fd</a>)</li>
<li><strong>Schema:</strong> update to v23.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/31e3a61194596f21b80904b0ffb86768180df13c">31e3a61</a>)</li>
<li><strong>Schema:</strong> update to v23.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/dc4cb436242dc1c37ec79a25222858bc20a2a83b">dc4cb43</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v21.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/57432dae4ae0348428aee57e80aab2616e9ae7d6">57432da</a>)</li>
<li><strong>Schema:</strong> update to v22.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/289e7bf6ad3b1750c5fb609b45e18cf602998e1b">289e7bf</a>)</li>
<li><strong>Schema:</strong> update to v24.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0cc48b54e236deb51c31b1b11725b34538cb1591">0cc48b5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v22.0.1...v22.0.2">22.0.2</a> (2024-03-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/df45c0599ffe529bfa7b2106fb407fb28ec81e10">df45c05</a>)</li>
<li><strong>Schema:</strong> update to v20.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3e0992fdd47553d1e684aa467411fa5dff10bbfe">3e0992f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v22.0.0...v22.0.1">22.0.1</a> (2024-03-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v19.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e40082739dce1fed401066c05e5e5b7ec162049f">e400827</a>)</li>
<li><strong>Schema:</strong> update to v19.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7e8e06cfacb33ced0949dbd14caaba4c6fc33b8b">7e8e06c</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.4.0...v22.0.0">22.0.0</a> (2024-03-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Footer:</strong> updated accessibility link (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9469d6ba12e229a71d3c3501de45a41e486899a4">9469d6b</a>)</li>
<li><strong>Schema:</strong> update to v18.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f7c111df2d1f0309a7d7b3105c20eab074d52807">f7c111d</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v18.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3a18ffbe36c5a24d52b34c729ba10cfebeb95682">3a18ffb</a>)</li>
<li><strong>Schema:</strong> update to v19.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8e9aa078f5a74034ba6b4645de8dd3e2fb3d4eda">8e9aa07</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> Comments/Link api change</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.3.2...v21.4.0">21.4.0</a> (2024-03-14)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Historical Records:</strong> updated share link to include hardware info (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d9b65f096f648a2e5c8dacb8ef4b9a16b3b80b5c">d9b65f0</a>)</li>
<li><strong>Inventory By Org:</strong> updated export to pdf (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fc4bbc9b280beb6dc160f5d31504667c0202ddfe">fc4bbc9</a>)</li>
<li><strong>Inventory due back:</strong> update to clear the expected return (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/01bd5558b6fad24be9c2d0e46e85aed148edcc1d">01bd555</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v18.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/313e65a145153a9ccd2acc1cdccf8ca594c006a0">313e65a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.3.1...v21.3.2">21.3.2</a> (2024-03-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Update dependencies. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/df18869d00057c8c0c22092acf3c2722ae80ac30">df18869</a>)</li>
<li><strong>Schema:</strong> update to v17.0.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/04a6111776b3f33f88e6fd62d972bca77a95641b">04a6111</a>)</li>
<li><strong>Table:</strong> parity with other COSMIC apps; adding functionality of BLOCK_OF_TEXT (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7f51990e7f16a37320781621773ce3f48f527341">7f51990</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.3.0...v21.3.1">21.3.1</a> (2024-03-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/47282024ebb5af685c3bfd03b04f91eeef7e327e">4728202</a>)</li>
<li><strong>COSMIC:</strong> Update dependencies. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9c96c10561f2950aa20c3fa076b4669e4d7562e7">9c96c10</a>)</li>
<li><strong>Schema:</strong> update to v17.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d7fbecace8a0b78ffade4e7cc0c95dd0fc31c5f3">d7fbeca</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.2.5...v21.3.0">21.3.0</a> (2024-03-07)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Contracts:</strong> updated locations tab to display... locations! (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/26e431eaadc55c53fbde2ecd6b8d64f69f92b21a">26e431e</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/579ef795643cfc0cbb13ba1652d6061ede70495e">579ef79</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/432c0da5a78fa845b1539680f66686928afec443">432c0da</a>)</li>
<li><strong>CriticalInventoryTracking Event creation:</strong> adding subType selector (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2232d09950476c66529393534698f33b7c67697d">2232d09</a>)</li>
<li><strong>Inventory Report:</strong> Only show inventory that is currently in inventory. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4c97a53166c34dd13f14bea55e93e265decc96fd">4c97a53</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v16.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3674c96905d0b96aa842b8b386f40743c6b153bc">3674c96</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.2.4...v21.2.5">21.2.5</a> (2024-02-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v15.0.7 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/24ca9302716f1383bb2324f830284a8bef10e55f">24ca930</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.2.3...v21.2.4">21.2.4</a> (2024-02-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Transaction Count By ProjectCode:</strong> update project code formatting in results and export. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bac6d2307c3526158094a7d50ea50d0d617683c9">bac6d23</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.2.2...v21.2.3">21.2.3</a> (2024-02-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Reporting:</strong> Transaction count by project code report update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/04e4197a743843569717464058bd7928d0276d0e">04e4197</a>)</li>
<li><strong>Schema:</strong> update to v15.0.6 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/230be2885157e2d37c94b228c0cc8ecc93948167">230be28</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.2.1...v21.2.2">21.2.2</a> (2024-02-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Chicklet:</strong> update to a brighter future (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/74f61b23ed447379013316593c72276712f81f3f">74f61b2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.2.0...v21.2.1">21.2.1</a> (2024-02-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v15.0.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a6005ca09702a0d5083595904f3c8d1c1350a702">a6005ca</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.1.1...v21.2.0">21.2.0</a> (2024-02-21)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Outage Alert:</strong> updating height to be consistent (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5386e2cfdf4cc05a29d6b2ee45e3c36905e963ba">5386e2c</a>)</li>
<li><strong>Schema:</strong> update to v15.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/75665ff3278925e253594cb09ec67a2695db6d2c">75665ff</a>)</li>
<li><strong>Schema:</strong> update to v15.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/40f05f3067358e5014b4e8751845f7fbfa24caa1">40f05f3</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v15.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/49fc3ec32f4ff5d0820f4415f3632a7d18e83e87">49fc3ec</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.1.0...v21.1.1">21.1.1</a> (2024-02-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>40hr report:</strong> added MPT since last service (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1ef27353fc78d61a3859b70809ad3b92e3be8aa1">1ef2735</a>)</li>
<li><strong>Chicklet:</strong> update to a more readonly denser view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/59bb777fe085faea4f16fdb70adef3dd5f365842">59bb777</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.0.5...v21.1.0">21.1.0</a> (2024-02-20)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Update dependencies. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5c89f237c9b1326c7fb1b69e44b34e6dce0d4d3c">5c89f23</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Contract:</strong> the ability to fully delete a Contract (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6199dda71cbb90527ce112996ad35e69743e729a">6199dda</a>)</li>
<li><strong>Hardware lists:</strong> ability to DELETE a hardware list (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b25bd8b7b359293c848d867c0e8a3a0148d0411b">b25bd8b</a>)</li>
<li><strong>Outages:</strong> Use API 2 for outages. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ec9f3c3edeacababd6ddb84b030621a5ee28b9b1">ec9f3c3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.0.4...v21.0.5">21.0.5</a> (2024-02-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4955e6316bdaa8ca861c2fe6009887a332c3fb63">4955e63</a>)</li>
<li><strong>Schema:</strong> update to v14.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/106bc09024215cc7e1fe64d5f8268258a1fbfd40">106bc09</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.0.3...v21.0.4">21.0.4</a> (2024-02-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Critical Inv:</strong> correct data display issue on the event popup (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2e5ec425adf905d4a5a1d1c53146ca8fc18bc8be">2e5ec42</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.0.2...v21.0.3">21.0.3</a> (2024-02-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Changelog report:</strong> update to standards (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e71d1fa9c96b1e4be03a3494f74ed211b5efcb0a">e71d1fa</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/db20e0fe3e15d9ea64eb499b27765e37c8d171b4">db20e0f</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ef5df571377927cbc6e0ad8edefef92837939b19">ef5df57</a>)</li>
<li><strong>Hardware dates report:</strong> update to standards (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2d488f9bae9ddfc2fa1ef139337afbc3948817c4">2d488f9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.0.1...v21.0.2">21.0.2</a> (2024-02-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>User:</strong> update how we find currentUserDelegateGroup (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/eb1bb8a1c9c3497cd80278214a039aaf28014777">eb1bb8a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v21.0.0...v21.0.1">21.0.1</a> (2024-02-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Chicklet view:</strong> update improves error handling (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7619c0b8cf5cbcf6a28904c099c202a3357c7888">7619c0b</a>)</li>
<li><strong>Critical Inventory tracking:</strong> update prevents error state when missing data (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/015e939a11e3bedc0d13e836e1323a06efb75d7a">015e939</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.27...v21.0.0">21.0.0</a> (2024-02-14)</h1>
<h3>Bug Fixes</h3>
<ul>
<li>add build:dev job to package.json (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/86db42ab9f55ca5e3a998594f740fdbe613f08f3">86db42a</a>)</li>
<li>CI template v4 update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/349dc8c8a6f94460b11d752810a523868f7e8864">349dc8c</a>)</li>
<li><strong>Critical inv:</strong> update corrects visual mapping of planar elements daftly plucked from the edges of reality (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b3f617aa7375ff322c6df9c439c014b540b76773">b3f617a</a>)</li>
<li><strong>Historical Records Report:</strong> Fix historical records report results. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/78d5bdf3d0e44e5b44673a4ca508044036a81c41">78d5bdf</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v14.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ee4bc04fd18905a0d6dfaeb76717556f4ab55150">ee4bc04</a>)</li>
<li><strong>User:</strong> update to new api (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/28e7fdfddc90d0ac2ada39cbf4be140b190b889f">28e7fdf</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>User:</strong> This updates to all new apis for user and user groups</li>
<li><strong>Schema:</strong> All User/UserGroup calls switch to api2</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.26...v20.0.27">20.0.27</a> (2024-02-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c0138025fd8da5c8c50662d718c395da943e59b0">c013802</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.25...v20.0.26">20.0.26</a> (2024-02-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d25d89a063ce29d01d7f9978e8c57c90d3c924d3">d25d89a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.24...v20.0.25">20.0.25</a> (2024-02-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v13.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6853c8be2b018ed5af2f99871717f0446e1bc74a">6853c8b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.23...v20.0.24">20.0.24</a> (2024-02-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c02f7a3831f4935416a7b4cb27887c1f1b2f1cdb">c02f7a3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.22...v20.0.23">20.0.23</a> (2024-02-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f6babc132ca3048b2dee0505176c5039155fd087">f6babc1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.21...v20.0.22">20.0.22</a> (2024-02-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Report views:</strong> update to be visually consistent across platforms (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e1fa0d7a81c797b88b958f6a825a4ca0c738e6e5">e1fa0d7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.20...v20.0.21">20.0.21</a> (2024-02-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f86f65468d525b8012b59eb30cdec71926d60a1b">f86f654</a>)</li>
<li><strong>Historical records:</strong> updated query string and links to use the correct id (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/74483171d20d2229e89ee8f51fd04d6d7aadbc5e">7448317</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.19...v20.0.20">20.0.20</a> (2024-02-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware BuildUp List:</strong> Fixed variable name for query variables passed in Hardware Build Up List. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9a60accf6baca74cbae96e2747485f336e0e6aa7">9a60acc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.18...v20.0.19">20.0.19</a> (2024-02-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5998399e950e688f1a07b318eef6cd35e4b53d32">5998399</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.17...v20.0.18">20.0.18</a> (2024-02-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v13.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d1fca8a1ac2f839f1074bc4d3516a461c8132850">d1fca8a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.16...v20.0.17">20.0.17</a> (2024-02-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Reports:</strong> Update components using item instance search. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cc219b7df6353919e734de34a197b40f2bd73b71">cc219b7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.15...v20.0.16">20.0.16</a> (2024-02-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4788c255fc46ef1aa9a5352b9b8f73d31bf2fb08">4788c25</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.14...v20.0.15">20.0.15</a> (2024-02-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v13.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6a361cd879e43461451062dcd12b70a8b7621ab9">6a361cd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.13...v20.0.14">20.0.14</a> (2024-02-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v13.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cc662d7e7499898113da71471251a4b0f91274b1">cc662d7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.12...v20.0.13">20.0.13</a> (2024-02-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e3311d5303044052404a71e69194f60b3d1835a5">e3311d5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.11...v20.0.12">20.0.12</a> (2024-02-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Inventory Transactions report:</strong> update to make it work (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7f23894a7ef159c73bc99b14641521d7914de336">7f23894</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.10...v20.0.11">20.0.11</a> (2024-02-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v13.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2d1ab36060b4c99ebedd4f80baaa9cebe6f951e8">2d1ab36</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.9...v20.0.10">20.0.10</a> (2024-02-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>TheNavbar:</strong> change z-index on container to accomodate tooltip z-index (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c7dd0816583dda59dcd7d27b690fadaa61e80112">c7dd081</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.8...v20.0.9">20.0.9</a> (2024-01-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Widgets:</strong> updated luminosity for the eventuality of a visual singularity (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f47d4e46378855f88aa063ee226a6bbde6427fdc">f47d4e4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.7...v20.0.8">20.0.8</a> (2024-01-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9b06e8acadcc8257e548836cc1a138ad87305ea7">9b06e8a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.6...v20.0.7">20.0.7</a> (2024-01-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Archive Hardware:</strong> Set default hardware selected on archive modal to none, fixed message and closed modal on save. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/598cc496a34ed77b212959e8cb96a3732ba8fba0">598cc49</a>)</li>
<li><strong>Hardware by drawing number:</strong> updated column widths for export to pdf (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e36008eca1a8b6079b964a1d7d931e922284be49">e36008e</a>)</li>
<li><strong>Schema:</strong> update to v13.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2d58b573e212c01db78d9b8722520cb8fa279298">2d58b57</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.5...v20.0.6">20.0.6</a> (2024-01-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Readiness:</strong> Improve export. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/49732b524c7efb04b06c08f44453c53d5ca4e866">49732b5</a>)</li>
<li><strong>Schema:</strong> update to v12.13.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9ebb1174a3194d0550678abd6752c5069660de47">9ebb117</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.4...v20.0.5">20.0.5</a> (2024-01-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.13.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ab0f6b3b48a35df7ae8137103a5770cde99d5075">ab0f6b3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.3...v20.0.4">20.0.4</a> (2024-01-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>LinkType:</strong> made the LinkTypes per context (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/139ca8778739de9f70caae7b3954e7a81e5e0c88">139ca87</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.2...v20.0.3">20.0.3</a> (2024-01-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9eb54cd2d6192a2ee7b6424714e60ce1d1ebf6a2">9eb54cd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.1...v20.0.2">20.0.2</a> (2024-01-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7b8c7e3f96ff51684bd00c8783866bd57bd7fd13">7b8c7e3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v20.0.0...v20.0.1">20.0.1</a> (2024-01-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.13.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/13192e704bfc5926a55f3bd2b21035a17abbef59">13192e7</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v19.0.6...v20.0.0">20.0.0</a> (2024-01-22)</h1>
<h3>Features</h3>
<ul>
<li><strong>Organizations:</strong> moved to api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f4d27481bb6e29aadf7cccb1c90b1b947ce77afb">f4d2748</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Organizations:</strong> Organization now on apiv2</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v19.0.5...v19.0.6">19.0.6</a> (2024-01-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>SearchUser:</strong> updated to use api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/80169d9b24056d3b3b97c5b401b18ea80aceac42">80169d9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v19.0.4...v19.0.5">19.0.5</a> (2024-01-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5486f02d3801e1cddf203815880ebc74b8ae4b01">5486f02</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v19.0.3...v19.0.4">19.0.4</a> (2024-01-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/155837adc83338ed3e9b23095239cfefb5b60443">155837a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v19.0.2...v19.0.3">19.0.3</a> (2024-01-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Readiness Report Export:</strong> strip HTML from disposition values (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f0040d8d8ede9cf8745e1ae1065caf1be4681377">f0040d8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v19.0.1...v19.0.2">19.0.2</a> (2024-01-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.11.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/98ab1d5ea2dec7bd2546b06caf1fbb5287e9e92b">98ab1d5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v19.0.0...v19.0.1">19.0.1</a> (2024-01-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.11.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2df6d341b3618819ea7910e57b292843ae90d318">2df6d34</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v18.2.7...v19.0.0">19.0.0</a> (2024-01-16)</h1>
<h3>Features</h3>
<ul>
<li><strong>Locations:</strong> move exclusively to api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6b6e3a67c4b190a4ba6034193b78bb792572a1ea">6b6e3a6</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Locations:</strong> APIv2 move</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v18.2.6...v18.2.7">18.2.7</a> (2024-01-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Contract Locations tab:</strong> update to api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ee37e0b14edb23f724bc6374631a1e1be35c4021">ee37e0b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v18.2.5...v18.2.6">18.2.6</a> (2024-01-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7c3e762a4b8748b90ff5e0cea0e046a2cde45644">7c3e762</a>)</li>
<li><strong>User groups:</strong> update corrects a slight miscalculation leading to structural modular damage. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9194986a9c3a40d09b400366be570f887b2c9f28">9194986</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v18.2.4...v18.2.5">18.2.5</a> (2024-01-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Logging:</strong> updated the logging controller (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/260648f738edc60ffeffe7697f58052aed31e023">260648f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v18.2.3...v18.2.4">18.2.4</a> (2024-01-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.10.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/93365303915dbe4413a9b0193bb21745c34cb2cf">9336530</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v18.2.2...v18.2.3">18.2.3</a> (2024-01-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Database:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c709610c43d2ea0c90b26d7b65fb12d2cfdb19f2">c709610</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v18.2.1...v18.2.2">18.2.2</a> (2024-01-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.9.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a1515fcd6794a73109c091b14f22e8c6b8db7d0f">a1515fc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v18.2.0...v18.2.1">18.2.1</a> (2024-01-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/66b9c41c5d5520c6294bf7fe855e51ca3126d568">66b9c41</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v18.1.0...v18.2.0">18.2.0</a> (2024-01-10)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v12.9.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6694965e0bd56976883710899758199749d3fa63">6694965</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v18.0.1...v18.1.0">18.1.0</a> (2024-01-10)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AssemblyTemplate IPL:</strong> Fix css to remove gray boxes in export. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/17949cf85c7394744fdb8e3fbdfda6494484fec8">17949cf</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Assembly Template:</strong> api2 updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/aa0826aece49bfaa686e9e7866ea8f92a2b41471">aa0826a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v18.0.0...v18.0.1">18.0.1</a> (2024-01-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4eb2fc77c1e74775e0c9b9f3ebf975b33b284398">4eb2fc7</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.21.9...v18.0.0">18.0.0</a> (2024-01-09)</h1>
<h3>Features</h3>
<ul>
<li><strong>Chicklet view:</strong> update allows a different view of the relationship between critical inventory and the available suits. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/68d3ff4a8190fbc179020d1cfefb0126281e8f88">68d3ff4</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Chicklet view:</strong> update includes items that are dangerous if it were to fall into the wrong paws</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.21.8...v17.21.9">17.21.9</a> (2024-01-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.6.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/14f06bce36a9a4ca1e1b6bad6be4e7ced2b81f73">14f06bc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.21.7...v17.21.8">17.21.8</a> (2024-01-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.6.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/14a88a434c9a92a45e1ca5bdb4d28a1f93988a08">14a88a4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.21.6...v17.21.7">17.21.7</a> (2024-01-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.6.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/887ce237981ee05e3f15961f4775bc2bcb954031">887ce23</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.21.5...v17.21.6">17.21.6</a> (2023-12-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Request Access:</strong> Remove unused imports. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c4e29e49aa68209fde6c71a4c2e40511bcd10354">c4e29e4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.21.4...v17.21.5">17.21.5</a> (2023-12-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Flight Life Report:</strong> Removed mysterious typo. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/abfa3a6d7521d0a95d9f1f97f2087fdc08802681">abfa3a6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.21.3...v17.21.4">17.21.4</a> (2023-12-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4643151a456cc7e4e0be43a1a2e296d5cc7cc154">4643151</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.21.2...v17.21.3">17.21.3</a> (2023-12-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/788f18c607e4bf1a97720310871da1fb405a0635">788f18c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.21.1...v17.21.2">17.21.2</a> (2023-12-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Inventory Due Back Report:</strong> increased results per page to 50, refreshing report on edits (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/28eb130346945c35364ba92a8b523f0554169a37">28eb130</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.21.0...v17.21.1">17.21.1</a> (2023-12-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>User:</strong> Current user calls moved to apiv2. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0223fda4fc26842030ce21fa10c8a5ba135bd90f">0223fda</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.20.5...v17.21.0">17.21.0</a> (2023-12-08)</h1>
<h3>Features</h3>
<ul>
<li><strong>Contracts:</strong> updated calls to APIv2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/62c78479721930c932d0eda800d0859f01a98806">62c7847</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.20.4...v17.20.5">17.20.5</a> (2023-12-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4d2e7ba0fbf13848115878336d11151105bc815e">4d2e7ba</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.20.3...v17.20.4">17.20.4</a> (2023-12-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Inventory report:</strong> update adds back the contract dropdown list (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d880d84162eca021150e6f6902b987691f895639">d880d84</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.20.2...v17.20.3">17.20.3</a> (2023-12-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d40c988242fa4d1b6dcbe15ca238c152408a3d7b">d40c988</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.20.1...v17.20.2">17.20.2</a> (2023-12-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Item Instance Transactions Tab:</strong> Added the ability to filter by the transaction type on the item instance transactions tab view. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0b0698c11bb1fba9b4d4bc6dc230021824651849">0b0698c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.20.0...v17.20.1">17.20.1</a> (2023-12-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/597df1d268f8384e4542dab3b3589ecb7d45481d">597df1d</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.19.5...v17.20.0">17.20.0</a> (2023-12-04)</h1>
<h3>Features</h3>
<ul>
<li><strong>Stage Separation:</strong> Separating concerns from contract specific requirements (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0d1dbe7fc2d8114c73def1e4c14703f9b0966ea1">0d1dbe7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.19.4...v17.19.5">17.19.5</a> (2023-12-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AssemblyTransactionReport:</strong> removed HTML (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/507dc6d8dbb94b02810a577fa401238a38985be3">507dc6d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.19.3...v17.19.4">17.19.4</a> (2023-12-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Readiness Report:</strong> Fixed alignment on pressurized time section and removed class from properties that are not dates. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d2152d70de64cfb2be11dfbed750fb62f628180d">d2152d7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.19.2...v17.19.3">17.19.3</a> (2023-11-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Title:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2c7253d48815bf6498c3b2d5906d444d4d637cfd">2c7253d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.19.1...v17.19.2">17.19.2</a> (2023-11-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ad7c91b9286d24e8c1b63a8cdaa757f4cb81b8c7">ad7c91b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.19.0...v17.19.1">17.19.1</a> (2023-11-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Active Project Codes:</strong> Hide pagination and show all results. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/da117b995a66d00ef8986a57a059a9fbb94ee393">da117b9</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.18.6...v17.19.0">17.19.0</a> (2023-11-28)</h1>
<h3>Features</h3>
<ul>
<li><strong>Inventory Due Back:</strong> ability to edit the estimated return date (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/dc14db3ffba97824db761983355e03024ea06dbb">dc14db3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.18.5...v17.18.6">17.18.6</a> (2023-11-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c2f51d8601b15cb1ee0ac61f5f470a23a9d639ba">c2f51d8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.18.4...v17.18.5">17.18.5</a> (2023-11-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Confirmation dialog:</strong> fixed issue where No button failed in certain cases (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a27a66a73c1dfd1de72041c22921272454131de3">a27a66a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.18.3...v17.18.4">17.18.4</a> (2023-11-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Forty Hour Report:</strong> updated share link, prevent table from resetting pagination on selection (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e890a941219f01347cd5709e5cbdce98be6afce6">e890a94</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.18.2...v17.18.3">17.18.3</a> (2023-11-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware List:</strong> removed date threshold styling from the birthdate (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2043b226e8e5969e148f85aa8d82648c3e48a521">2043b22</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.18.1...v17.18.2">17.18.2</a> (2023-11-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2d456e6f3b3a42d145fd747bfe7f1dddca568bf4">2d456e6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.18.0...v17.18.1">17.18.1</a> (2023-11-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6edcb7aaca40740f54d9723c20eba72ded290f07">6edcb7a</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.16...v17.18.0">17.18.0</a> (2023-11-13)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v12.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e6b067d8fb2005c603f40ba39699dcff56058840">e6b067d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.15...v17.17.16">17.17.16</a> (2023-11-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Readiness:</strong> display issue corrected. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ecc5a8941d7c65a89473addb757eb52af3b1e98d">ecc5a89</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.14...v17.17.15">17.17.15</a> (2023-11-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Readiness:</strong> correct export issue not showing part number (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/75e5e8ba614a20a12c46a1a1f745b5cd13a1da82">75e5e8b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.13...v17.17.14">17.17.14</a> (2023-11-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Display:</strong> results (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bea129783fa7497529246b7a3edc68485b135e9d">bea1297</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.12...v17.17.13">17.17.13</a> (2023-11-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Deployment:</strong> updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3f9f8d3db376b79f5eaaec7f4756bafc9eda1ef1">3f9f8d3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.11...v17.17.12">17.17.12</a> (2023-11-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.19.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f045ca373b650c754c381be064ded9a4a37cbd52">f045ca3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.10...v17.17.11">17.17.11</a> (2023-11-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AssemblyDocumentChangeSets:</strong> Update to use new helpurl (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8848af5d7a1f44d0f998b5b3248820824ead30a6">8848af5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.9...v17.17.10">17.17.10</a> (2023-11-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Search:</strong> correct UI where the count was being displayed twice (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f8e53acc5666587b544d11cfaf462ecc7da833d6">f8e53ac</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.8...v17.17.9">17.17.9</a> (2023-11-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.19.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e32f8882a6fe04be208724314dc7b824f6a28ff6">e32f888</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.7...v17.17.8">17.17.8</a> (2023-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Readiness:</strong> various report improvements. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/842a901461928fc8e5da622f8437c45580bd6a9a">842a901</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.6...v17.17.7">17.17.7</a> (2023-11-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assembly Documents Report:</strong> Delist report. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7be7f6e8e993c3c74d123a1dbe724c45d7b9b3dd">7be7f6e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.5...v17.17.6">17.17.6</a> (2023-11-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Item Instance:</strong> transaction tab now shows all Transactions (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/505c1e88abea3c1694aeb5dace3689194b3c7f8b">505c1e8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.4...v17.17.5">17.17.5</a> (2023-11-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.19.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b8ce843b517892972db9a9f0a806e8568cf84ac5">b8ce843</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.3...v17.17.4">17.17.4</a> (2023-11-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware comments:</strong> update to report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4f06d5091179750cae4c7647601464dfc2a14706">4f06d50</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.2...v17.17.3">17.17.3</a> (2023-10-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Dep:</strong> updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b61c0564bb1f2b9b9f68956c128268bc973e8366">b61c056</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.1...v17.17.2">17.17.2</a> (2023-10-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Contracts:</strong> updated manage page to standards (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/957e764ece2b8c71ad370b51b16c6ccacb7b5d90">957e764</a>)</li>
<li><strong>Events:</strong> end date is not required so fixed the UI to reflect this. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d041eefbc92dc3c4ef094e1701fd48f59feee111">d041eef</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.17.0...v17.17.1">17.17.1</a> (2023-10-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>40hr report:</strong> Show all tags when more than one inventory, item drawing or subtype selected and add inline clear icons for clearing minis. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/735febc53ff7ad3eb5642d2d80acdfc5eb19a77f">735febc</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.16.6...v17.17.0">17.17.0</a> (2023-10-26)</h1>
<h3>Features</h3>
<ul>
<li><strong>Inventory Transactions report:</strong> added new Transaction types to query. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3608f2d7031edaedfca1a326348ae41136a6c15c">3608f2d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.16.5...v17.16.6">17.16.6</a> (2023-10-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ChangeSet Report:</strong> removed unused and mislabeled report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ef235970fab925496632ec383d322dc3ff5c6296">ef23597</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.16.4...v17.16.5">17.16.5</a> (2023-10-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Reporting tables:</strong> update to current standards. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d180826d1c3ed8b266cc01dbc90d4428a0ab5f57">d180826</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.16.3...v17.16.4">17.16.4</a> (2023-10-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.18.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/20f901ac5f1f092f6714bf58fe82e581207b8c08">20f901a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.16.2...v17.16.3">17.16.3</a> (2023-10-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.18.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6994f9e323e31aab8ffaae677b078e778eeb8538">6994f9e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.16.1...v17.16.2">17.16.2</a> (2023-10-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Inventory Report:</strong> updated query, removed column on pdf export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cff167606af86ba5f1ccbf20bb5c3ea85bae2206">cff1676</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.16.0...v17.16.1">17.16.1</a> (2023-10-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Deployment:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1a0821b2bed042f8a07478917e0d8d4ee9d900e0">1a0821b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.15.4...v17.16.0">17.16.0</a> (2023-10-19)</h1>
<h3>Features</h3>
<ul>
<li><strong>Sign-in flow:</strong> route to correct URL after authenticating (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5bdd9689bce526a014f790fa7aed0b2c9cd26d6c">5bdd968</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.15.3...v17.15.4">17.15.4</a> (2023-10-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware List:</strong> update functionality (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9960f89daf1e0cd38083d017190078f767bb3216">9960f89</a>)</li>
<li><strong>Inventory By Bin Report:</strong> Added table to display when there are no results (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a962539378b191fe3909867e23fc25e96bc0b067">a962539</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.15.2...v17.15.3">17.15.3</a> (2023-10-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Inventory:</strong> update reports. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fc57de51089bb4ad1148c29405d745c934af465e">fc57de5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.15.1...v17.15.2">17.15.2</a> (2023-10-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Deployment:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/52bcb0a1f5ed8c75a34a6a00bf97886549feadbb">52bcb0a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.15.0...v17.15.1">17.15.1</a> (2023-10-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Events:</strong> added loader to Information tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fbaa3c7123eccaaebac4cfe6a4f4c99b7562d04c">fbaa3c7</a>)</li>
<li><strong>Reporting:</strong> Fixed Hardware by Drawing number search (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ffc0447d870194eb95903611ab80eb1d63850c27">ffc0447</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.14.1...v17.15.0">17.15.0</a> (2023-10-18)</h1>
<h3>Features</h3>
<ul>
<li><strong>Hardware lists:</strong> UX overhaul and brought up to current design standards. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a23f25050340f834d7748d1fd9207984b5b4307a">a23f250</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.14.0...v17.14.1">17.14.1</a> (2023-10-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assemblies:</strong> restored ability to unassign Assembly Templates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/e730b9d0ddf702a2b773ca74d35159da5c71c4f2">e730b9d</a>)</li>
<li><strong>Home:</strong> visual consistency (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0fddfc8e741c9dc99e3b8abdbce4f4f1301d7f25">0fddfc8</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.13.0...v17.14.0">17.14.0</a> (2023-10-12)</h1>
<h3>Features</h3>
<ul>
<li><strong>Public assets:</strong> swapped locations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6c55454f08c096eb59e565e0944977dec9adca24">6c55454</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.12.1...v17.13.0">17.13.0</a> (2023-10-12)</h1>
<h3>Features</h3>
<ul>
<li><strong>Tooltips:</strong> improved legibility and performance (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bd82ed56fa52802454e37b6370b5d1e820d2add7">bd82ed5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.12.0...v17.12.1">17.12.1</a> (2023-10-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>TabItemMasterAssemblyTemplate:</strong> Changed AppTableExpandable to AppTableActions; Created info modal for assembly template; (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/54e7e3bca638d2330f485d2b532f00af9e91870e">54e7e3b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.11.10...v17.12.0">17.12.0</a> (2023-10-11)</h1>
<h3>Features</h3>
<ul>
<li><strong>Hardware list template:</strong> quick redesign to make the ux in line with design standards (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/da9998b5fe3439ea436dfe470055db06b6267766">da9998b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.11.9...v17.11.10">17.11.10</a> (2023-10-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Deployment:</strong> readiness for deployment. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1e0d5a94cfc9a668b3e01717dec20771a8f45c28">1e0d5a9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.11.8...v17.11.9">17.11.9</a> (2023-10-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>ReportingShelfLife:</strong> corrects various UI issues (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fc628a8b28ecff6cbbd073131447181060cafa1b">fc628a8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.11.7...v17.11.8">17.11.8</a> (2023-10-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assembly template:</strong> update corrects &quot;create&quot; menu silent error (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9e4e257f9293eeae0d9b405ed3ce42a9fbf3b20c">9e4e257</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.11.6...v17.11.7">17.11.7</a> (2023-10-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Inventory report:</strong> update weird display (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3c48de3c307fe0e83ed034fb9e0dac3705f71798">3c48de3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.11.5...v17.11.6">17.11.6</a> (2023-10-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.17.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2899207e8d627d331c71a50d41615789879ae364">2899207</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.11.4...v17.11.5">17.11.5</a> (2023-10-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Developer:</strong> integration commit (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/580b1c50d764f7edd6d44b5f922aadf43325bd8f">580b1c5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.11.3...v17.11.4">17.11.4</a> (2023-10-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FortyHRReport:</strong> Fixed query string to allow equipment class to load on back button when only one is selected. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/47ef0df8b1fae29688cc210eb24a5ffee9b7535d">47ef0df</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.11.2...v17.11.3">17.11.3</a> (2023-10-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Assemblies:</strong> fixed assigning Assembly Templates from Item Masters (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d4904f5fc24e2638e49a080245841df4e87ec232">d4904f5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.11.1...v17.11.2">17.11.2</a> (2023-09-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Deployment update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/17ccfa878ebeacacc218a096135d65b63534fb8a">17ccfa8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.11.0...v17.11.1">17.11.1</a> (2023-09-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Deployment update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/898183190169970463b031051445eb5983300df8">8981831</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.10.0...v17.11.0">17.11.0</a> (2023-09-28)</h1>
<h3>Features</h3>
<ul>
<li><strong>DocumentListing Report:</strong> Added support for filtering by close dates. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9835a7e82202ab30a939c9b2f1366c7e99f010ce">9835a7e</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.49...v17.10.0">17.10.0</a> (2023-09-28)</h1>
<h3>Features</h3>
<ul>
<li><strong>InventoryByOrgLocation:</strong> Added export for Inventory By Organization. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4008edce058a242312700bd8060e4ca9fc062987">4008edc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.48...v17.9.49">17.9.49</a> (2023-09-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Approvals:</strong> remove extra words (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f7d0e99504f29e3def69eaedfbd2cee640e5f768">f7d0e99</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.47...v17.9.48">17.9.48</a> (2023-09-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.17.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8c06f8b5510098494b7e85218b8cc556806bb590">8c06f8b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.46...v17.9.47">17.9.47</a> (2023-09-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Deployment update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7f6145fb5f669d952a1d005fe21bfd2a503838b4">7f6145f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.45...v17.9.46">17.9.46</a> (2023-09-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>inventory By Org:</strong> updated preset for SSATA (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/184b5532dc0c33b0fe8215f48c0e2c2f315dd0d9">184b553</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.44...v17.9.45">17.9.45</a> (2023-09-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Deployment update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/156da556c8ff77d7426f59de9283e7474aaf1a7d">156da55</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.43...v17.9.44">17.9.44</a> (2023-09-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Events manage:</strong> HardwareList tab visual updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/eaa6ea29727cfe6079ede74336bab9cf46381b26">eaa6ea2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.42...v17.9.43">17.9.43</a> (2023-09-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Initial Receipt Condensed:</strong> fixed link to 911 tags (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/642dcfdf2abae7b0b50d806a23aefd8b23adc360">642dcfd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.41...v17.9.42">17.9.42</a> (2023-09-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Readiness Report:</strong> updated box 12 text to show value from print options form (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/25553e777f58c122019608d0769dde400c7d8e53">25553e7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.40...v17.9.41">17.9.41</a> (2023-09-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Inventory Transactions report:</strong> update to remove the edit pencil (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9e7ee1bbc94ea7eb13140c6160187e7cc0186155">9e7ee1b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.39...v17.9.40">17.9.40</a> (2023-09-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Deployment update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8db9bd6292179e16dfb2e19c168561c90a7a9208">8db9bd6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.38...v17.9.39">17.9.39</a> (2023-09-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Hardware Build Up List:</strong> fixed sort order on export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/223acf1c0cd217fb1969c31434ec0b59418bebef">223acf1</a>)</li>
<li><strong>Hardware Search:</strong> fix max records input (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ef4344fd1b153249cd46418433ce2f53fb8d8988">ef4344f</a>)</li>
<li><strong>ReadinessReport:</strong> Correct variable name so that Document subType shows in type column on export. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8b4cad005d838604bba46851f5961605a754d0bd">8b4cad0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.37...v17.9.38">17.9.38</a> (2023-09-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Project codes:</strong> update project code nomenclature (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0343977aa9c1c9583258339d8dc0ea7649f25ce8">0343977</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.36...v17.9.37">17.9.37</a> (2023-09-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.17.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/3692e016ed055410763b679aaea511358e402130">3692e01</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.35...v17.9.36">17.9.36</a> (2023-09-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.17.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/41af470803c15954aeea6cdf7d34fd4a469ac73f">41af470</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.34...v17.9.35">17.9.35</a> (2023-09-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activity Search:</strong> corrected data issue on display (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2cce1de69f4c660e88d18772e92605e356654351">2cce1de</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.33...v17.9.34">17.9.34</a> (2023-09-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f65c2efda5c2fe8ae9f4dd0a6310ceae00e68b3c">f65c2ef</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.32...v17.9.33">17.9.33</a> (2023-09-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AppContact:</strong> removing the lazy version of AppContact (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a09fc17af1c0f7180a9c2122ec4759d3687d3fcd">a09fc17</a>)</li>
<li><strong>Hardware Search:</strong> using new ultra fast endpoint for search (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9f34423594148081bf4d0068327a582b361bdbe8">9f34423</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.31...v17.9.32">17.9.32</a> (2023-09-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.17.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/811397a7a258a3edcfd2633c2cbfb441d9d59e50">811397a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.30...v17.9.31">17.9.31</a> (2023-09-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fff3a6b0dc0429b54a32fdb062f7ed5c88f653b0">fff3a6b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.29...v17.9.30">17.9.30</a> (2023-09-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Design standards:</strong> update to newly defined UI standards (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1763cbe435308aa59d62f5ac5969f728aaf16edc">1763cbe</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.28...v17.9.29">17.9.29</a> (2023-09-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>TheSideNav:</strong> Moved changlog above external links. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d24a4293af3d3ae7a63dcf2d18c18d0f495c3499">d24a429</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.27...v17.9.28">17.9.28</a> (2023-09-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d4287f6eae29e793f66c4db8bdfbb10cf863d4c4">d4287f6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.26...v17.9.27">17.9.27</a> (2023-09-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Tables:</strong> first implementation of a new table standard (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fa6a04b598b0ebf92c14b016c9f6f89a89d3182e">fa6a04b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.25...v17.9.26">17.9.26</a> (2023-09-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>DocumentListing:</strong> Add back export. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ff0a0e61d7433d9e7a5b528114962a261f63b6fb">ff0a0e6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.24...v17.9.25">17.9.25</a> (2023-09-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/eff698e254deffd77f82c248bd51b60f2e809592">eff698e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.23...v17.9.24">17.9.24</a> (2023-09-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Modals:</strong> standardized all modals (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a30307604ab4296fa646a156e4a5976a4442a19d">a303076</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.22...v17.9.23">17.9.23</a> (2023-08-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c17cab6fef12987fb1aa79fe69b344c31e25cbaa">c17cab6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.21...v17.9.22">17.9.22</a> (2023-08-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activity:</strong> reporting Activity search ux improvements (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fc40d4797d695e0ba04bbc39e183def14284b80f">fc40d47</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.20...v17.9.21">17.9.21</a> (2023-08-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.16.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b3aa13e4b7a2a3815879f27df6f7cca660f80532">b3aa13e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.19...v17.9.20">17.9.20</a> (2023-08-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.16.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/017b8cee77619e22eeb28484f36e0e30e8649acd">017b8ce</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.18...v17.9.19">17.9.19</a> (2023-08-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Biannual 1324:</strong> Fixed transaction dates. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/45ea7e238b4d77261d2798d03d87061f7132437a">45ea7e2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.17...v17.9.18">17.9.18</a> (2023-08-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.16.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/21899610572373835399d27d6fdda54b23713559">2189961</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.16...v17.9.17">17.9.17</a> (2023-08-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.16.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c4de0d5fbd432b5f1a83551ece4d63b62feac8ba">c4de0d5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.15...v17.9.16">17.9.16</a> (2023-08-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bdf6b1b43740cb12bee148e04c2ef3425c1c3fa3">bdf6b1b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.14...v17.9.15">17.9.15</a> (2023-08-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Activity Search:</strong> update ux on requiredness (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bf111634e7bc9af8119437f7da63838daa725b3a">bf11163</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.13...v17.9.14">17.9.14</a> (2023-08-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/93630000a33b6b918e60b2955e2cded043e33871">9363000</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.12...v17.9.13">17.9.13</a> (2023-08-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Events:</strong> added delete to event manage page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/749cd0b1e290aaee4b3ae68d4dc9a97547fb9996">749cd0b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.11...v17.9.12">17.9.12</a> (2023-08-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Readiness Report:</strong> updated class for Usage Life Items on export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/39fab92e1f4b118163191e36fb87b016a8f5a682">39fab92</a>)</li>
<li><strong>Readiness:</strong> Update sources for CEI Number. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d4f35b028975a28da6631ccd2cf078bd78ae8e4e">d4f35b0</a>)</li>
<li><strong>Transaction count by project code:</strong> complete rewrite of report. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b2744d7d026fbe92a8aff16ef72f7f4c78526502">b2744d7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.10...v17.9.11">17.9.11</a> (2023-08-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.14.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/c036223dd187e74f0fcf0153110b1bcbf6da768e">c036223</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.9...v17.9.10">17.9.10</a> (2023-08-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Inventory By Bin:</strong> sorted results by location information, fix repeating header on export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d2aa08578d03f811ff9c04a0d20140a4f9e8c2d1">d2aa085</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.8...v17.9.9">17.9.9</a> (2023-08-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Critical Inventory Tracking:</strong> Non-COSMIC bug fix and UI tweaks (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/85decfdfbf36b6d92a320034c89ec2616a09fe69">85decfd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.7...v17.9.8">17.9.8</a> (2023-08-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.13.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9b9134c5c38d7880a1fd91c832d33148b91089be">9b9134c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.6...v17.9.7">17.9.7</a> (2023-08-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Standards:</strong> naming Drawing Num consistently to Drawing num (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9c58d490a62d2e1cc70d2d8ad3553222a1903409">9c58d49</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.5...v17.9.6">17.9.6</a> (2023-08-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.13.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0d629d1b47e9b6b78cdced3bf0541cb1c80fd331">0d629d1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.4...v17.9.5">17.9.5</a> (2023-08-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Critical Inventory Tracking:</strong> delete items, bounds checking, UI tweaks (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/fe52584a632b72feb4b001968dde929c03f85cc3">fe52584</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.3...v17.9.4">17.9.4</a> (2023-08-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/7b7649d98396b411b802d87a307bfeac72fe6e6c">7b7649d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.2...v17.9.3">17.9.3</a> (2023-08-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>DocumentListing ReportView:</strong> Remove concat of documents on the query results. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4ce7ac008e52606838f246b45dfb647f63f6b43f">4ce7ac0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.1...v17.9.2">17.9.2</a> (2023-08-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Limited LIfe Alerts:</strong> updated Usage alert to show birth date (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/a41256a30eab5889cd122a68e603fe0e0f46b347">a41256a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.9.0...v17.9.1">17.9.1</a> (2023-08-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>footer:</strong> updated link to user guide (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/970fa1303d03a6c31bb0ae694717d5832fab2f4f">970fa13</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.8.2...v17.9.0">17.9.0</a> (2023-08-17)</h1>
<h3>Features</h3>
<ul>
<li><strong>Critical Inventory Tracking:</strong> support COSMIC Inventory (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/bb7d401445f727ff69af75072b923fe73c4c31aa">bb7d401</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.8.1...v17.8.2">17.8.2</a> (2023-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.12.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/0eeb52ba955fcab9273af57ee537bf21ed4c895b">0eeb52b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.8.0...v17.8.1">17.8.1</a> (2023-08-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.11.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/6741b193830a19f41165f9399b844a27739dd063">6741b19</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.7.7...v17.8.0">17.8.0</a> (2023-08-14)</h1>
<h3>Features</h3>
<ul>
<li><strong>Reporting:</strong> new Activity search! (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/31830b5ae58f83c074ce66ddc646ee5b15a7a6d3">31830b5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.7.6...v17.7.7">17.7.7</a> (2023-08-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> manage user group update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/04d386c2af23e07d04c9fc1c1b387604795d0d8d">04d386c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.7.5...v17.7.6">17.7.6</a> (2023-08-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Side navigation:</strong> added uniform styling. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cb22e41ad74fd26e3097bb02e37ea0bcbb502e4b">cb22e41</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.7.4...v17.7.5">17.7.5</a> (2023-08-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/992e81529c8146bf3af1b4ff5f237c6f28360cea">992e815</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.7.3...v17.7.4">17.7.4</a> (2023-08-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/8d805a804c95c64fdae89f578a6647e888d3d079">8d805a8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.7.2...v17.7.3">17.7.3</a> (2023-08-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AppNumber:</strong> update to remove listeners (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/d0dc3f2b38c915d4a60dee6e60cf05685dc954c7">d0dc3f2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.7.1...v17.7.2">17.7.2</a> (2023-08-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>AccessRequests:</strong> Users can now request access on their own from their User manage page. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/95ad3269b997af1af70c052f741aed44ca1f983e">95ad326</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.7.0...v17.7.1">17.7.1</a> (2023-08-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>InventoryByOrg Report:</strong> UX updates removes the requirement to select a Location, enabling the users to see Inventory at the Org level. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/cf92ddd9e1fa0e520c543cf2204b21085bb6a7df">cf92ddd</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.6.8...v17.7.0">17.7.0</a> (2023-08-09)</h1>
<h3>Features</h3>
<ul>
<li><strong>Critical Inventory Tracking:</strong> added event plotting (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/25ec487497e10c101f51e70192098e41fd4a0322">25ec487</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.6.7...v17.6.8">17.6.8</a> (2023-08-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>User permissions:</strong> updated to show a subset of the permissions based on context the user is in (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/770b19a998c780dcf55abfd82260405de33b5925">770b19a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.6.6...v17.6.7">17.6.7</a> (2023-08-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/75841da73bcf345bc97232014b14a0fd2fbda42a">75841da</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.6.5...v17.6.6">17.6.6</a> (2023-08-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Events Manage:</strong> Build up list tab updates. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4ba6aa15e9cc60c52589a5596e8161126f4a582b">4ba6aa1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.6.4...v17.6.5">17.6.5</a> (2023-08-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Events Manage:</strong> visual updates. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/1e86bc31d9bde9c394591857b1f983c8c90fc428">1e86bc3</a>)</li>
<li><strong>Schema:</strong> update to v11.10.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/73a42961520184437bdef2bcd6eff5805706eb51">73a4296</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.6.3...v17.6.4">17.6.4</a> (2023-08-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Permissions:</strong> per context permissions. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/feea749e359443c2d5690b4bd36ac64a5d073254">feea749</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.6.2...v17.6.3">17.6.3</a> (2023-08-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/b17f2ae4a078cff6c625c42eb0280744ad807775">b17f2ae</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.6.1...v17.6.2">17.6.2</a> (2023-08-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/f7976b78730aef9f989a855bdc417435ee9bde7b">f7976b7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.6.0...v17.6.1">17.6.1</a> (2023-08-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Readiness:</strong> Added SCU export defaults. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/377a2c9d69739728752409fa16b837b460bfdf50">377a2c9</a>)</li>
<li><strong>Readiness:</strong> corrected link to report with an Event. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/5ce4262c70774cc69f180f51e02cc261f2eff8c3">5ce4262</a>)</li>
<li><strong>Schema:</strong> update to v11.10.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/2bc5e89f9b6089dbd0542140c9ce6bd0433eb3c1">2bc5e89</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.5.3...v17.6.0">17.6.0</a> (2023-08-03)</h1>
<h3>Features</h3>
<ul>
<li><strong>Comments:</strong> the only protected comment type is SYSTEM. All other types are open. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/4a1d5fde0735f7068d80f6562f62e38d07ac8dd0">4a1d5fd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.5.2...v17.5.3">17.5.3</a> (2023-08-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.10.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/ab6296984dbef1e4c4896b51ed31577d59fe4269">ab62969</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.5.1...v17.5.2">17.5.2</a> (2023-08-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Table dependency updates. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/commit/9a72e5e89a3cd09c5fa1cd85b4c637974b1591ce">9a72e5e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/og/compare/v17.5.0...v17.5.1">17.5.1</a> (2023-08-01)</h2>
</section></template><script>export default {components:{}}</script>